import { request } from 'lib/request';
import Router from 'lib/router';
import { addUrlQuery } from 'lib/utils/url';

export const parseForm = (form: HTMLFormElement) => {
  const values: Record<string, string> = {};
  for (const el of Array.from(form.elements) as HTMLInputElement[]) {
    if (el.name) {
      if (el.type === 'checkbox') {
        values[el.name] = el.checked ? el.value ?? 'on' : '';
      } else {
        values[el.name] = el.value;
      }
    }
  }
  return values;
};

export const clearForm = (form: HTMLFormElement): void => {
  for (const el of Array.from(form.elements) as HTMLInputElement[]) el.value = '';
};

export const formAsyncSubmit = (e: SubmitEvent) => {
  e.preventDefault();

  const form = e.target as HTMLFormElement;

  const body = parseForm(form);

  if (form.method.toLowerCase() === 'get') {
    Router.push(addUrlQuery(form.action, body));
  } else {
    request({ url: form.action, method: form.method, body });
  }
};
