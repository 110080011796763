import nprogress from 'nprogress';
import React, { useEffect } from 'react';

import Router from 'lib/router';

const NProgress = () => {
  useEffect(() => {
    let timeout;

    const start = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => nprogress.start(), 100);
    };

    const done = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      nprogress.done();
    };

    Router.events.on('routeChangeStart', start);
    Router.events.on('routeChangeComplete', done);
    Router.events.on('routeChangeError', done);

    return () => {
      done();
      Router.events.off('routeChangeStart', start);
      Router.events.off('routeChangeComplete', done);
      Router.events.off('routeChangeError', done);
    };
  }, []);

  return (
    <style jsx global>{`
      #nprogress {
        pointer-events: none;
      }
      #nprogress .bar {
        background: #4768df;
        position: fixed;
        z-index: 1031;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
      }
      /* Fancy blur effect */
      #nprogress .peg {
        display: block;
        position: absolute;
        right: 0px;
        width: 100px;
        height: 100%;
        box-shadow: 0 0 10px #7289da, 0 0 5px #7289da;
        opacity: 1;
        -webkit-transform: rotate(3deg) translate(0px, -4px);
        -ms-transform: rotate(3deg) translate(0px, -4px);
        transform: rotate(3deg) translate(0px, -4px);
      }
    `}</style>
  );
};

export default NProgress;
