export const UNKNOWN = 0;
export const BAD_REQUEST = 400; // user provided invalid information
export const UNAUTHORIZED = 401; // not signed in, incorrect sign in, etc.
export const FORBIDDEN = 403; // no access to a given resource
export const NOT_FOUND = 404; // resource DNE
export const SERVER_ERROR = 500;

const DEFAULT_ERROR_MESSAGES = {
  [UNKNOWN]: 'Unknown Error',
  [BAD_REQUEST]: 'Invalid data',
  [UNAUTHORIZED]: 'Unauthorized',
  [FORBIDDEN]: 'Forbidden',
  [NOT_FOUND]: 'Not Found',
  [SERVER_ERROR]: 'Unknown Error',
} as Record<number, string | undefined>;

type ErrorMeta = Record<string, any>;
export class ExternalError extends Error {
  code: number;
  status: number;
  statusCode: number;
  meta: ErrorMeta;
  type: string;
  expose = true; // koa requires this to display these error messages to users
  constructor(code: number, message?: string, meta?: ErrorMeta, type = 'external_error') {
    super(message || DEFAULT_ERROR_MESSAGES[code] || DEFAULT_ERROR_MESSAGES[UNKNOWN]);
    this.status = this.statusCode = this.code = code || 0;
    this.meta = meta || {};
    this.type = type;
  }

  toJSON() {
    return {
      name: 'ExternalError',
      message: this.message,
      type: this.type,
      code: this.code,
      meta: this.meta || {},
    };
  }

  static fromJSON(json: ReturnType<typeof ExternalError['prototype']['toJSON']>) {
    return new ExternalError(json.code, json.message, json.meta, json.type);
  }
}

export const serializeError = (err: Error) => {
  if (err instanceof ExternalError) {
    return err.toJSON();
  } else {
    return {
      name: err.name ?? null,
      message: err.message ?? null,
      code: (err as any).code ?? null,
    };
  }
};
export const deserializeError = (json: any) => {
  if (json.name === 'ExternalError') {
    return ExternalError.fromJSON(json);
  } else {
    const err = new Error(json.message);
    (err as any).code = json.code;
    return err;
  }
};

export const createError = (code: number, message?: string, meta?: ErrorMeta): ExternalError =>
  new ExternalError(code, message, meta);

type ThrowHelper = (message?: string, meta?: ErrorMeta) => never;

// helpers for commonly used errors
export const throwUnauthorized: ThrowHelper = (message, meta) => {
  throw new ExternalError(UNAUTHORIZED, message, meta);
};
export const throwForbidden: ThrowHelper = (message, meta) => {
  throw new ExternalError(FORBIDDEN, message, meta);
};
export const throwNotFound: ThrowHelper = (message, meta) => {
  throw new ExternalError(NOT_FOUND, message, meta);
};
export const throwBadRequest: ThrowHelper = (message, meta) => {
  throw new ExternalError(BAD_REQUEST, message, meta);
};
export const throwNotLoggedIn = (expiredSession = false): never => {
  throw new ExternalError(UNAUTHORIZED, 'Please log in first', {
    expiredSession,
    notLoggedIn: true,
  });
};
