import * as _ from 'lodash-es';

import { CONSTRAIN_ZOOM } from 'lib/constants/search';

/**
 * Credit: https://github.com/rmrs/random-location
 */

const EARTH_RADIUS = 6371000; // meters
const DEG_TO_RAD = Math.PI / 180.0;
const THREE_PI = Math.PI * 3;
const TWO_PI = Math.PI * 2;

const toRadians = (deg: number): number => deg * DEG_TO_RAD;
const toDegrees = (rad: number): number => rad / DEG_TO_RAD;

type RandomFn = () => number;
export type Point = { lat: number; lng: number };

/**
 * Given a centerPoint C and a radius R, returns a random point that is on the
 * circumference defined by C and R.
 * Based on: http://www.movable-type.co.uk/scripts/latlong.html#destPoint
 */
export const randomCircumferencePoint = (
  centerPoint: Point,
  radiusMeters: number,
  randomFn: RandomFn = Math.random,
): Point => {
  const sinLat = Math.sin(toRadians(centerPoint.lat));
  const cosLat = Math.cos(toRadians(centerPoint.lat));

  // Random bearing (direction out 360 degrees)
  const bearing = randomFn() * TWO_PI;
  const sinBearing = Math.sin(bearing);
  const cosBearing = Math.cos(bearing);

  // Theta is the approximated angular distance
  const theta = radiusMeters / EARTH_RADIUS;
  const sinTheta = Math.sin(theta);
  const cosTheta = Math.cos(theta);

  const rLatitude = Math.asin(sinLat * cosTheta + cosLat * sinTheta * cosBearing);

  let rLongitude =
    toRadians(centerPoint.lng) +
    Math.atan2(sinBearing * sinTheta * cosLat, cosTheta - sinLat * Math.sin(rLatitude));

  // Normalize longitude L such that -PI < L < +PI
  rLongitude = ((rLongitude + THREE_PI) % TWO_PI) - Math.PI;

  return { lat: toDegrees(rLatitude), lng: toDegrees(rLongitude) };
};

/**
 * Given a centerPoint C and a radius R, returns a random point that is inside
 * the circle defined by C and R.
 */
export const randomCirclePoint = (
  centerPoint: Point,
  radiusMeters: number,
  randomFn: RandomFn = Math.random,
): Point => {
  // http://mathworld.wolfram.com/DiskPointPicking.html
  return randomCircumferencePoint(centerPoint, Math.sqrt(randomFn()) * radiusMeters, randomFn);
};

/**
 * Returns a random point in a region bounded by two concentric circles (annulus).
 */
export const randomAnnulusPoint = (
  centerPoint: Point,
  innerRadiusMeters: number,
  outerRadiusMeters: number,
  randomFn: RandomFn = Math.random,
): Point => {
  if (innerRadiusMeters >= outerRadiusMeters) {
    throw new Error(
      `innerRadius (${innerRadiusMeters}) should be smaller than outerRadius (${outerRadiusMeters})`,
    );
  }

  const deltaRadius = outerRadiusMeters - innerRadiusMeters;

  return randomCircumferencePoint(
    centerPoint,
    innerRadiusMeters + Math.sqrt(randomFn()) * deltaRadius,
    randomFn,
  );
};

/**
 * Returns the distance in meters between two points P1 and P2.
 *
 * Basically it is the Haversine distance function.
 * Based on: http://www.movable-type.co.uk/scripts/latlong.html
 */
export const distanceM = (P1: Point, P2: Point): number => {
  const rP1 = {
    lat: toRadians(P1.lat),
    lng: toRadians(P1.lng),
  };
  const rP2 = {
    lat: toRadians(P2.lat),
    lng: toRadians(P2.lng),
  };

  const D = {
    lat: Math.sin((rP2.lat - rP1.lat) / 2),
    lng: Math.sin((rP2.lng - rP1.lng) / 2),
  };

  const A = D.lat * D.lat + D.lng * D.lng * Math.cos(rP1.lat) * Math.cos(rP2.lat);

  const C = 2 * Math.atan2(Math.sqrt(A), Math.sqrt(1 - A));

  return EARTH_RADIUS * C;
};

/**
 * Returns distance between two points in kilometers
 */
export const distanceKm = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
  return distanceM({ lat: lat1, lng: lng1 }, { lat: lat2, lng: lng2 }) / 1000.0;
};

export const approxZoomFromRadius = (radius: number, lat: number, mapRadiusPixels = 350) => {
  // the inverse of this equation (https://gis.stackexchange.com/a/127949):
  // radius = 0.001 * mapRadiusPixels * 156543.03392 * cos(lat * PI / 180) / 2^zoom

  const zoom =
    -1.4426950409 *
    Math.log((0.0063880197985 * radius) / (mapRadiusPixels * Math.cos((lat * Math.PI) / 180)));

  return Math.round(zoom);
};

const latRad = (lat: number): number => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
};

const axisZoom = (mapPx: number, worldPx: number, fraction: number): number => {
  return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
};

// export const midPoint = (p1: Point, p2: Point): Point => {
//   const dLng = latRad(p2.lng - p1.lng);

//   const lat1 = latRad(p1.lat);
//   const lat2 = latRad(p2.lat);
//   const lng1 = latRad(p2.lng);

//   const cosLat1 = Math.cos(lat1);
//   const cosLat2 = Math.cos(lat2);

//   const Bx = cosLat2 * Math.cos(dLng);
//   const By = cosLat2 * Math.sin(dLng);

//   return {
//     lat: Math.atan2(
//       Math.sin(lat1) + Math.sin(lat2),
//       Math.sqrt((cosLat1 + Bx) * (cosLat1 + Bx) + By * By),
//     ),
//     lng: lng1 + Math.atan2(By, cosLat1 + Bx),
//   };
// };

// returns km
export const getMapRadius = (northEast: Point, southWest: Point): number => {
  const w = distanceKm(northEast.lat, northEast.lng, northEast.lat, southWest.lng);
  const h = distanceKm(northEast.lat, northEast.lng, southWest.lat, northEast.lng);

  // 1.2 just adds a bit of a buffer to the radius
  return (1.2 * Math.min(w, h)) / 2;
};

const WORLD_DIM = { height: 256, width: 256 };
const DEFAULT_MAP_SIZE = { width: 500, height: 600 };
export const getBoundsZoomLevel = ({
  northEast,
  southWest,
  mapWidth,
  mapHeight,
}: {
  northEast: { lat: number; lng: number };
  southWest: { lat: number; lng: number };
  mapWidth?: number;
  mapHeight?: number;
}): [zoom: number, radius: number] => {
  mapWidth ||= DEFAULT_MAP_SIZE.width;
  mapHeight ||= DEFAULT_MAP_SIZE.height;

  const radius = getMapRadius(northEast, southWest);

  const latFraction = (latRad(northEast.lat) - latRad(southWest.lat)) / Math.PI;

  const lngDiff = northEast.lng - southWest.lng;
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = axisZoom(mapHeight, WORLD_DIM.height, latFraction);
  const lngZoom = axisZoom(mapWidth, WORLD_DIM.width, lngFraction);

  const zoom = _.clamp(Math.min(latZoom, lngZoom), CONSTRAIN_ZOOM.min, CONSTRAIN_ZOOM.max);

  // could use this instead? it's slightly less accurate
  // const zoom = approxZoomFromRadius(
  //   radius,
  //   midPoint(northEast, southWest).lat,
  //   Math.hypot(mapWidth / 2, mapHeight / 2),
  // );

  return [zoom, radius];
};

export const kmToMiles = (km: number): number => km * 0.62137;
export const milesToKm = (miles: number): number => miles * 1.6093;
