import NextRouter from 'next/router';

import routeResolver from 'config/routes.mjs';
import { trackError } from 'lib/tracking';
import { HOST, IS_FUNCTIONS, IS_PROD_DATA, IS_SSR } from 'lib/env';

type Params = Record<string, any>;

export const matchRoute = (pathname: string): { route?: { name: string }; params?: Params } =>
  routeResolver.matchRoute(pathname);

export const extractParams = (pathname: string): Params => matchRoute(pathname).params || {};

export const getPath = (routeName: string, params: Params = {}): string => {
  const pathname = routeResolver.getPath(routeName, params);

  if (pathname) return pathname;

  if (!IS_PROD_DATA || IS_FUNCTIONS)
    throw new Error(`Route not found with name '${routeName}' and params: ${Object.keys(params)}`);

  return '';
};

export const getUrl = (routeName: string, params?: Params) =>
  `${HOST}${getPath(routeName, params)}`;

const Router = routeResolver.getRouter(NextRouter);

if (!IS_SSR && Router.events) {
  // Don't keep focus on anchor tags when we change routes
  // This makes sense on a SPA
  Router.events.on('routeChangeComplete', () => {
    const active = document.activeElement as HTMLAnchorElement | null;
    if (active && active.tagName === 'A') active.blur();
  });

  Router.events.on('routeChangeError', (err, asPath, params) => {
    if (err.cancelled) return;

    trackError(err, 'routeChangeError', { asPath, params });
  });
}

export default Router;
