import * as _ from 'lodash-es';
import { Subject, BehaviorSubject } from 'rxjs';
import { useEffect } from 'react';

import { IS_SSR } from 'lib/env';
import usePropsState from 'lib/hooks/usePropsState';

export { Subject, BehaviorSubject };

export const waitForNext = async <V>(subject: BehaviorSubject<V | undefined>): Promise<V> => {
  if (subject.value !== undefined) return subject.value;

  const value = await new Promise<V>((resolve) => {
    const sub = subject.subscribe((v) => {
      if (v !== undefined) {
        resolve(v);
        sub.unsubscribe();
      }
    });
  });

  return value;
};

type AsyncObserver<T> = {
  value: T;
  next: (value: T) => void;
  subscribe: (fn: (value: T) => void) => () => void;
};

export const createObserver = <T>(
  initialValue: T,
  subFn: (thisObserver: AsyncObserver<T>) => void | (() => void),
  alwaysOn = false,
): AsyncObserver<T> => {
  const sub = new BehaviorSubject(initialValue);

  let unsubFn: undefined | (() => void);

  const observer = {
    get value() {
      return sub.value;
    },
    next(value: T) {
      sub.next(value);
    },
    subscribe(fn: (value: T) => void) {
      if (IS_SSR) return _.noop; // disable `createObserver` server-side

      if (alwaysOn ? !unsubFn : sub.observers.length === 0) {
        const unsubFn_ = subFn(observer);
        if (_.isFunction(unsubFn_)) unsubFn = unsubFn_;
      }

      const subsciption = sub.subscribe(fn);

      return () => {
        subsciption.unsubscribe();

        if (!alwaysOn && sub.observers.length === 0) unsubFn?.();
      };
    },
  };

  return observer;
};

export const useObserver = <T>(subject: AsyncObserver<T> | null | undefined): T | undefined => {
  const [val, setVal] = usePropsState(subject?.value);

  useEffect(() => subject?.subscribe(setVal), [subject]);

  return val;
};

export const withTimeout =
  <Args extends any[], R>(fn: (...args: Args) => Promise<R>, timeout: number) =>
  (...args: Args): Promise<R | undefined> =>
    Promise.race([
      new Promise<undefined>((r) => setTimeout(() => r(undefined), timeout)),
      fn(...args),
    ]);
