import * as _ from 'lodash-es';
import * as NodeSentry from '@sentry/node'; // only defined for backend
import { Device } from '@capacitor/device'; // only defined for MOBILE_APP
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'; // only defined for MOBILE_APP
import * as BrowserSentry from '@sentry/browser'; // only defined for browser
import type firebase from 'firebase/compat/app';

import { browserDb } from 'lib/db/shared';
import {
  RELEASE_ID,
  GTAG_ID,
  DEPLOY_ENV,
  MOBILE_APP,
  IS_SSR,
  IS_DEPLOYED,
  IS_FUNCTIONS,
  IS_DEV_ENV,
  PLATFORM,
} from 'lib/env';

const globalParams: Record<string, string> = {
  platform: PLATFORM,
  deploy_env: DEPLOY_ENV,
};

let mobileAnalytics: typeof FirebaseAnalytics | undefined;
let analytics: firebase.analytics.Analytics | undefined;
let Sentry: typeof NodeSentry | typeof BrowserSentry | undefined;

if (IS_DEPLOYED && !IS_SSR) {
  if (MOBILE_APP) mobileAnalytics = FirebaseAnalytics;
  else analytics = browserDb.app.analytics();
}

export const getDeviceUUID = _.memoize(async () => {
  if (!Device) return null;
  try {
    return (await Device.getId()).identifier;
  } catch {}
  return null;
});
if (MOBILE_APP && !IS_SSR) {
  getDeviceUUID().then((id) => {
    if (id) globalParams.device_uuid = id;
  });
}

const initSentry = (_Sentry?: typeof Sentry) => {
  if (!_Sentry) return;

  const dsn = IS_SSR
    ? process.env.SENTRY_DSN
    : MOBILE_APP
    ? process.env.NEXT_PUBLIC_SENTRY_MOBILE_DSN
    : process.env.NEXT_PUBLIC_SENTRY_BROWSER_DSN;

  if (!dsn) return;

  Sentry = _Sentry;

  Sentry.init({
    dsn,
    environment: DEPLOY_ENV,
    release: RELEASE_ID,
    ignoreErrors: IS_SSR
      ? []
      : [
          'IndexedDB transaction failed: QuotaExceededError',
          'Connection WebChannel transport errored',
        ],
  });
};

if (IS_DEPLOYED) {
  if (IS_SSR) {
    initSentry(NodeSentry);
    if (!Sentry && IS_FUNCTIONS && IS_DEPLOYED) {
      throw new Error('Failed to initialize Sentry');
    }
  } else {
    initSentry(BrowserSentry);
  }
}

export const setTrackingUser = (user?: firebase.User | null) => {
  const userId = user?.uid || '';

  if (userId) globalParams.user_id = userId;
  else delete globalParams.user_id;

  try {
    if (analytics) analytics.setUserId(userId);
    else if (mobileAnalytics)
      mobileAnalytics.setUserId({
        userId,
      });
  } catch {}

  if (Sentry) {
    try {
      Sentry.setUser(
        user
          ? {
              email: user.email || undefined,
              username: user.displayName || undefined,
              id: user.uid,
            }
          : null,
      );
    } catch {}
  }
};

const consoleLevels = {
  fatal: 'error',
  error: 'error',
  warning: 'warn',
  info: 'log',
  log: 'log',
  debug: 'debug',
} as const;

type ConsoleLevel = keyof typeof consoleLevels;

type Details = Record<string, any>;

type TrackError = {
  (msg: string, details?: Details, level?: ConsoleLevel): Promise<void>;
  (error: Error, msg: string, details?: Details): Promise<void>;
  (error: Error, details?: Details): Promise<void>;
};

const safeStringify = (a: any) => {
  try {
    return JSON.stringify(a);
  } catch {
    return `${a}`;
  }
};
const errorToString = (err: Error) => {
  return `${err.name}: ${err.message}\nerr_props=${safeStringify({ ...err })}\n`;
};

export const trackError: TrackError = async (a: any, b?: any, c?: any) => {
  let msg: string | undefined,
    error: Error | undefined,
    details: Details | undefined,
    level: ConsoleLevel = 'error';
  if (typeof a === 'string') {
    msg = a;
    details = b;
    if (c) level = c;
  } else if (typeof b === 'string') {
    error = a;
    msg = b;
    details = c;
  } else {
    error = a;
    details = b;
  }

  if (_.isEmpty(details)) details = undefined;

  let tracked = false;
  try {
    (console[consoleLevels[level]] || console.error)(
      ...[
        MOBILE_APP && error ? errorToString(error) : error,
        msg,
        MOBILE_APP && details ? safeStringify(details) : details,
      ].filter(Boolean),
    );

    if (Sentry) {
      Sentry.withScope((scope) => {
        scope.setTags(_.omit(globalParams, 'deploy_env', 'user_id'));

        if ((error as any)?.meta) scope.setExtra('error_meta', (error as any).meta);
        if (details) scope.setExtras(details);

        if (error) {
          if (msg) scope.setTransactionName(msg);

          Sentry!.captureException(error);
        } else if (msg) {
          scope.setLevel(level);
          Sentry!.captureMessage(msg);
        }
      });

      if (IS_SSR) await Sentry.flush(2000);
      tracked = true;
    }
  } catch {}

  if (!tracked && IS_SSR && IS_DEPLOYED) console.error('Sentry is disabled server-side!');
};

export const withErrorTracking = <V extends (...args: any[]) => any>(
  fn: V,
  extra?: Record<string, any>,
): V =>
  (async (...args) => {
    try {
      const res = await fn(...args);
      return res;
    } catch (err) {
      await trackError(err, extra);
      throw err;
    }
  }) as V;

export const trackEvent = (eventName: string, details: Record<string, any> = {}) => {
  details = { ...globalParams, ...details };

  if (IS_DEV_ENV) {
    console.log('[trackEvent]', eventName, MOBILE_APP ? safeStringify(details) : details);
  }

  try {
    if (analytics) analytics.logEvent(eventName, details);
    else if (mobileAnalytics)
      mobileAnalytics.logEvent({
        name: eventName,
        params: details,
      });
  } catch {}
};

export const trackPageView = (url: string, isInitial = false) => {
  if (isInitial) {
    trackEvent('initial_view', { url });
  }
  trackEvent('screen_view', { url, ...(isInitial ? { is_initial: true } : {}) });
};

export const gtagEvent = (...args: any[]) => {
  if (GTAG_ID && typeof window !== 'undefined') ((window as any).dataLayer ||= []).push(args);
};
export const gtagConversionEvent = (eventId: string) => {
  gtagEvent('event', 'conversion', {
    send_to: `${GTAG_ID}/${eventId}`,
    value: 1.0,
    currency: 'USD',
  });
};

export const debugWindowValue = (key: string, value: any) => {
  if (IS_DEV_ENV && typeof window !== 'undefined') ((window as any).VANLY ||= {})[key] = value;
};

// configure google tag manager
if (!IS_SSR && !MOBILE_APP && GTAG_ID) {
  gtagEvent('js', new Date());
  gtagEvent('config', GTAG_ID);
}
