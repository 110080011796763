import { useCallback, useRef } from 'react';

/**
 * never have to worry about useCallback dependencies.
 * Returns a stable reference, but always uses the latest function scope.
 */
export function useLatestCallback<T extends (...args: any[]) => any>(callback: T): T {
  const callbackRef = useRef<T>(callback);
  callbackRef.current = callback;

  const latestCallback = useCallback((...args: any[]) => callbackRef.current(...args), []) as T;

  return latestCallback;
}
