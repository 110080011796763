import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

type State = null | 'start-in' | 'in' | 'active' | 'start-out' | 'out';

const useTransition = (active: boolean, timeout: number): State => {
  active = !!active;

  const [state, setState] = useState<State>(null);

  const prevActive = usePrevious(active);

  const catchTransition = state === 'in' || state === 'out';
  useEffect(() => {
    if (catchTransition) {
      const timer = setTimeout(() => {
        setState((current) => (current === 'start-in' || current === 'in' ? 'active' : null));
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catchTransition]);

  useEffect(() => {
    if (!prevActive && active) setState('start-in');
    else if (prevActive && !active) setState('start-out');
  }, [prevActive, active]);
  useEffect(() => {
    if (state === 'start-in') setTimeout(() => setState('in'), 60);
    else if (state === 'start-out') setTimeout(() => setState('out'), 60);
  }, [state]);

  return state;
};

export default useTransition;
