import { Subject } from 'lib/utils/async';

const scriptSubjects: Record<string, Subject<void>> = {};

export const loadScript = (url: string): Promise<void> => {
  let sub = scriptSubjects[url];

  if (!sub) {
    sub = scriptSubjects[url] = new Subject();

    const isCss = url.endsWith('.css');

    const el = document.createElement(isCss ? 'link' : 'script') as HTMLScriptElement;
    el.addEventListener('load', () => {
      sub.complete();
    });
    el.addEventListener('error', (evt) => {
      sub.error(new Error(`Failed to load ${url}: ${evt.message}`));
    });
    if (isCss) {
      el.setAttribute('rel', 'stylesheet');
      el.setAttribute('href', url);
    } else {
      el.setAttribute('src', url);
    }
    document.head.appendChild(el);
  }

  return sub.toPromise();
};
