import * as _ from 'lodash-es';

import routeResolver from 'config/routes.mjs';
import { NEXT_BUILD_ID } from 'lib/env';
import { rawRequest } from 'lib/request';
import { getPath } from 'lib/router';
import { trackError } from 'lib/tracking';
import { createError, NOT_FOUND, UNAUTHORIZED } from 'lib/utils/errors';
import { absUrl } from 'lib/utils/url';

class SspError extends Error {
  code: number;
  status: number;
  res: Response;
  constructor(res: Response, message = null) {
    super(message || res.statusText);
    this.code = this.status = res.status;
    this.res = res;
  }
}

export const fetchPageProps = async (asPath: string, deserialize = false) => {
  let fetchUrl: string | undefined;

  try {
    const { route, params } = routeResolver.matchRoute(asPath);

    const pagePath = route ? route.getHref({ ...params, __format: 'json' }) : null;

    if (!pagePath) {
      const url = absUrl(asPath);
      console.log('Browser.open external url:', url);
      return { redirectTo: url, forceBrowserOpen: true };
    }

    if (route.redirect) {
      trackError('fetchPageProps cannot fetch redirect route', {
        pagePath,
        redirectName: route.redirect,
        asPath,
      });

      return { error: createError(NOT_FOUND) };
    }

    fetchUrl = `/_next/data/${NEXT_BUILD_ID}${pagePath}`;

    const { res, resData } = await rawRequest<{ pageProps: Record<string, any> } | string>({
      url: fetchUrl,
      headers: {
        'content-type': undefined, // not needed
      },
      deserialize,
    });

    const pageProps = typeof resData === 'object' ? resData?.pageProps : null;

    if (!pageProps || !_.isPlainObject(pageProps)) {
      trackError('fetchPageProps invalid response', {
        fetchUrl,
        asPath,
        status: res.status,
        resData: typeof resData === 'string' ? resData.slice(0, 256) : resData,
      });

      return { error: new SspError(res) };
    }

    const redirectTo = pageProps.__N_REDIRECT;
    if (redirectTo) {
      return {
        redirectTo: redirectTo as string,
      };
    }

    if (res.status === UNAUTHORIZED && pageProps.__error?.meta?.notLoggedIn) {
      return {
        redirectTo: getPath('login', { login_page: 'login', from: asPath }),
      };
    }

    if (!res.ok) {
      return {
        error: new SspError(res),
      };
    }

    return { pageProps };
  } catch (error) {
    trackError(error, 'fetchPageProps', { fetchUrl, asPath });

    return { error };
  }
};
