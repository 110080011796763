import Link from 'components/Link';
import SocialLinks from 'components/social/SocialLinks';

const Footer = () => (
  <>
    <footer className="footer">
      <div className="section pt-0">
        <hr style={{ backgroundColor: '#BFBDA3' }} />
        <div className="tw-flex tw-flex-col sm:tw-grid sm:tw-grid-cols-5 tw-gap-6 sm:tw-gap-3">
          <div className="tw-flex tw-items-center tw-justify-center sm:tw-justify-start">
            <small>© 2024 Vanly, Co. All rights reserved.</small>
          </div>
          <div className="tw-flex tw-items-center tw-justify-center tw-gap-3 font-sans-serif tw-flex-wrap">
            <small>
              <Link href="/blog">Blog</Link>
            </small>
            <small>
              <Link href="https://trymasterkey.com?utm_source=wanderglade&utm_medium=footer&utm_campaign=referral">
                Masterkey
              </Link>
            </small>
            <small>
              <Link href="/terms">Terms</Link>
            </small>
            <small>
              <Link href="/privacy">Privacy</Link>
            </small>
          </div>
          <div className="flex items-center justify-center font-sans-serif">
            <small>hello@wanderglade.com</small>
          </div>
          <div className="flex items-center justify-center sm:tw-justify-end sm:tw-col-span-2">
            <SocialLinks className="has-text-centered" />
          </div>
        </div>
      </div>
    </footer>

    <style jsx>{`
      @import 'styles/variables';

      footer {
        color: $grey-dark;
        background-color: $champagne;

        :global(a) {
          color: $ebony;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    `}</style>
  </>
);

export default Footer;
