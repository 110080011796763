import * as _ from 'lodash-es';
import type firebase from 'firebase/compat/app';

import db from 'lib/db/shared';
import { trackError } from 'lib/tracking';
import { serializeError, deserializeError } from 'lib/utils/errors';

const { Firestore, firestore } = db;

// TODO: we may be able to optimize this
// e.g. we don't need to serialize/deserialize when server-side-rendering

type SerializedItem = {
  [key: string]: any;
  __sp: 'Date' | 'Timestamp' | 'DocumentReference' | 'Error' | 'GeoPoint';
};
type SerializedPrimitive = SerializedItem | null | string | number | boolean;
export type SerializedJSON =
  | SerializedPrimitive
  | { [key: string]: SerializedJSON; __sp: never }
  | SerializedJSON[];

const isDocRef = (value: any): value is firebase.firestore.DocumentReference =>
  value.firestore && value.type === 'document';

const serialize = (value: unknown): SerializedJSON => {
  if (value !== null && typeof value === 'object') {
    if (_.isPlainObject(value))
      return _.mapValues(value, serialize) as { [key: string]: SerializedJSON; __sp: never };
    if (Array.isArray(value)) return value.map(serialize);
    if (value instanceof Date) return { millis: value.getTime(), __sp: 'Date' };
    if (value instanceof Firestore.DocumentReference || isDocRef(value))
      return { path: value.path, __sp: 'DocumentReference' };
    if (value instanceof Firestore.Timestamp)
      return { seconds: value.seconds, nanoseconds: value.nanoseconds, __sp: 'Timestamp' };
    if (value instanceof Firestore.GeoPoint)
      return { latitude: value.latitude, longitude: value.longitude, __sp: 'GeoPoint' };
    if (value instanceof Error) return { ...serializeError(value), __sp: 'Error' };
  }

  if (value == null) return null;

  if (typeof value === 'string' || typeof value === 'boolean' || typeof value === 'number')
    return value;

  // NOTE: this might not get tracked since we're not calling `await`
  trackError('Unsupported serialize value', { value }, 'warning');
  return null;
};

const deserialize = (value: SerializedJSON): any => {
  if (value !== null && typeof value === 'object') {
    if (Array.isArray(value)) return value.map(deserialize);
    if (value.__sp) {
      switch (value.__sp) {
        case 'DocumentReference':
          return firestore.doc(value.path);
        case 'Timestamp':
          return new Firestore.Timestamp(value.seconds, value.nanoseconds);
        case 'GeoPoint':
          return new Firestore.GeoPoint(value.latitude, value.longitude);
        case 'Date':
          return new Date(value.millis);
        case 'Error':
          return deserializeError(value);
        default:
          return null;
      }
    }
    return _.mapValues(value, deserialize);
  }
  return value;
};

export default { serialize, deserialize };
