import * as _ from 'lodash-es';
import { useCallback, useRef, useState } from 'react';

/**
 * Similar to `useState`, but updates the state whenever `propsValue`
 * changes (without causing an extra rerender)
 */
const usePropsState = <T>(propsValue: T) => {
  const [, update] = useState(false);

  const state = useRef<{ value: T; prevPropValue?: T }>({ value: propsValue });

  const setState = useCallback((value: T | ((prev: T) => T)) => {
    if (_.isFunction(value)) {
      state.current.value = value(state.current.value);
    } else {
      state.current.value = value;
    }
    update((tick) => !tick);
  }, []);

  if (state.current.prevPropValue !== propsValue) {
    state.current.prevPropValue = propsValue;
    state.current.value = propsValue;
  }

  return [state.current.value, setState] as const;
};

export default usePropsState;
