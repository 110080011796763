// NOTE: this file is read by both server and browser

export const AUTH_TOKEN_HEADER = 'x-vanly-authtoken';

export const USER_ID_HEADER = 'x-vanly-uid';

export const PLATFORM_HEADER = 'x-vanly-platform';

// headers allowed in CORS for mobile app
// NOTE: must match `allowHeaders` in `lib/mobile/nextMobileAppPlugin`
export const ALLOW_HEADERS = ['content-type', AUTH_TOKEN_HEADER, USER_ID_HEADER, PLATFORM_HEADER];

export const OTP_QUERY_KEY = 'otp_token';
