import React from 'react';

import Banner from './Banner';

const PreviewModeBanner = () => {
  return (
    <Banner showBelowTop short>
      <div className="container flex justify-between items-center">
        <span
          className="has-text-weight-bold tooltip is-tooltip-bottom is-tooltip-multiline"
          data-tooltip="CMS content like blog posts will display their latest content i.e. Drafts"
        >
          Preview mode enabled <i className="fas fa-question-circle" />
        </span>
        <a className="button is-warning is-inverted" href="/api/admin/cms/preview?disable=1">
          Disable
        </a>
      </div>
    </Banner>
  );
};

export default PreviewModeBanner;
