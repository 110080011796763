import React from 'react';
import * as _ from 'lodash-es';
import { useMediaQuery, Context } from 'react-responsive';

import { IS_SSR } from 'lib/env';
import { mobileSize, tabletSize } from 'lib/constants/styles';

export { useMediaQuery };

export const useIsMobile = () => useMediaQuery({ maxWidth: mobileSize });

export const useReducedMotion = () =>
  useMediaQuery({
    query: '(prefers-reduced-motion)',
  });

const DEFAULT_WIDTH = tabletSize + 1;

export const ResponsiveProvider: React.FC<{
  ssrWidth?: number | null;
  children: React.ReactNode;
}> = IS_SSR
  ? ({ children, ssrWidth }) => {
      return (
        <Context.Provider value={{ width: ssrWidth || DEFAULT_WIDTH }}>{children}</Context.Provider>
      );
    }
  : ({ children }) => children as React.ReactElement;

export const getBrowserName = _.memoize(async () => {
  try {
    const UAParser = (await import('ua-parser-js')).default;
    return new UAParser().getBrowser().name?.toLowerCase() || null;
  } catch {
    return null;
  }
});
