import * as _ from 'lodash-es';
import type firebase from 'firebase/compat/app';
import type { Serwist } from '@serwist/window';

import { IS_DEPLOYED, IS_SSR, MOBILE_APP, PUBLIC_VAPID_KEY } from 'lib/env';
import { trackError } from 'lib/tracking';
import { browserDb } from 'lib/db/shared';
import { onAuthStateChanged } from 'lib/auth';
import { waitOnceForEvent } from 'lib/hooks/events';
import { getBrowserName } from 'components/Responsive';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    serwist?: Serwist;
  }
}

type InitPushNotificationsArgs = {
  onUpdateFcmToken: (token: string | null) => MaybePromise<void>;
  onActiveDeviceNotification: (payload: {
    title: string;
    body: string | null;
    link: string | null;
    notification_id: string | null;
  }) => MaybePromise<void>;
};

export type InitPushNotifications = (args: InitPushNotificationsArgs) => (() => void) | void;

const { app } = browserDb;

let messaging: firebase.messaging.Messaging | undefined;

const attemptSetup = !IS_SSR && !MOBILE_APP;

const registerSw = _.memoize(async () => {
  if (!attemptSetup) return null;

  let reg: ServiceWorkerRegistration | undefined;
  try {
    if (process.env.SW_PATH && 'serviceWorker' in navigator) {
      reg = await window.serwist?.register();
      // reg = await navigator.serviceWorker.register(process.env.SW_PATH);
    }
  } catch (err) {
    trackError(err, 'serviceWorker.register');
  }

  return reg || null;
});

const setupNotifications = async ({
  onUpdateFcmToken,
  onActiveDeviceNotification,
}: InitPushNotificationsArgs): Promise<void> => {
  const swRegistration = await registerSw();

  try {
    messaging ||= app.messaging();
  } catch {}

  if (!swRegistration || !messaging) return;

  let permission: NotificationPermission | undefined;

  await waitOnceForEvent(
    [
      [window, 'click'],
      [window, 'keydown'],
    ],
    async () => {
      try {
        permission = await Notification.requestPermission();
      } catch (err) {
        console.error('failed to request Notification permission', err);
      }
    },
  );

  if (!permission || permission === 'denied') return;

  try {
    const fcmToken = await messaging.getToken({
      serviceWorkerRegistration: swRegistration,
      vapidKey: PUBLIC_VAPID_KEY,
    });

    await onUpdateFcmToken(fcmToken);
  } catch (err) {
    console.error('Failed to get messaging FCM token', err);
    await onUpdateFcmToken(null);
  }

  messaging.onMessage({
    next: (payload: firebase.messaging.MessagePayload) => {
      const title = payload?.notification?.title;
      if (title) {
        onActiveDeviceNotification({
          title,
          body: payload.notification?.body || null,
          link: payload.fcmOptions?.link || null,
          notification_id: payload.data?.notification_id || null,
        });
      }
    },
    error: (err) => void trackError(err, 'messaging.onMessage'),
    complete: () => {},
  });
};

export const initPushNotifications: InitPushNotifications = (args) => {
  if (!attemptSetup) return;

  const unsubAuth = onAuthStateChanged(async (authUser) => {
    if (IS_DEPLOYED && authUser && (await getBrowserName()) !== 'safari') {
      try {
        await setupNotifications(args);
      } catch (err) {
        trackError(err, 'setupNotifications');
      }
    }
  });

  // start initializing the service worker immediately
  registerSw();

  return () => {
    unsubAuth();
  };
};
