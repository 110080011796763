import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import * as _ from 'lodash-es';

import { formatDate, timeToMillis, TimeToMillisInput } from 'lib/utils/time';

const DEFAULT_FORMAT = 'PPpp';

type TimeFormat = ((millis: number) => string | null) | string | null;

// TODO: the page will jump a bit because the value is null on the first render
// we can fix this be use date-fns-tz and providing the timezone we think the user
// is in (passed through React Context)
export const useFormatDate = (
  time: TimeToMillisInput = Date.now(),
  format: TimeFormat = null,
  { fromNow = false, timeZoneId }: { fromNow?: boolean; timeZoneId?: string } = {},
): string | null => {
  const [str, setStr] = useState<string | null>(null);

  const millis = timeToMillis(time);

  useEffect(() => {
    if (millis == null) return;

    if (fromNow) setStr(formatDistanceToNow(millis, { addSuffix: true }));
    else {
      setStr(
        formatDate(millis, (_.isFunction(format) ? format(millis) : format) ?? DEFAULT_FORMAT, {
          timeZoneId,
        }),
      );
    }
  }, [millis, format, fromNow, timeZoneId]);

  return str;
};

export const FormatDate: React.FC<{
  time?: TimeToMillisInput;
  format?: TimeFormat;
  fromNow?: boolean;
  timeZoneId?: string;
}> = ({ time, format = null, fromNow = false, timeZoneId }) => {
  const formatted = useFormatDate(time, format, { fromNow, timeZoneId });
  return (formatted || '\u00A0') as unknown as JSX.Element;
};

export const FORMAT_SHORT_DAY: TimeFormat = (millis) =>
  new Date().getFullYear() === new Date(millis).getFullYear() ? 'MMM do' : 'MMM do, yyy';
