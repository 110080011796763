import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
// @ts-expect-error missing dependency
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

let stack = 0;
let htmlOverflow: string | undefined;

export const useLockScroll = (enabled = true) => {
  enabled = !!enabled;

  const ref = useRef();

  useIsomorphicLayoutEffect(() => {
    if (!enabled) return;

    const el = ref.current;

    if (stack === 0) {
      htmlOverflow = window.getComputedStyle(document.documentElement).overflow;

      document.documentElement.style.overflow = 'hidden';
    }

    stack++;

    disablePageScroll(el);

    return () => {
      enablePageScroll(el);

      stack--;

      if (stack === 0) {
        document.documentElement.style.overflow = htmlOverflow || 'hidden auto';
      }
    };
  }, [enabled]);

  return ref;
};
