import type firebase from 'firebase/compat/app';

import { RootDocs, ROOT_COLLECTIONS, SUB_COLLECTIONS } from 'lib/constants/db';

export const buildCollectionHelpers = <
  Firestore extends firebase.firestore.Firestore | FirebaseFirestore.Firestore,
>(
  firestore: Firestore,
) => {
  type CollectionReference<Doc> = Firestore extends firebase.firestore.Firestore
    ? firebase.firestore.CollectionReference<Doc>
    : FirebaseFirestore.CollectionReference<Doc>;

  type SubCollec<Doc> = <DocId extends string | undefined>(
    docId: DocId,
  ) => DocId extends string ? CollectionReference<Doc> : firebase.firestore.Query<Doc>;

  return {
    ...ROOT_COLLECTIONS.reduce(
      (memo, name) => {
        memo[name] = firestore.collection(name) as any;
        return memo;
      },
      {} as {
        [key in typeof ROOT_COLLECTIONS[number]]: CollectionReference<RootDocs[key]>;
      },
    ),
    ...SUB_COLLECTIONS.reduce(
      (memo, [rootName, subName, keyName]) => {
        memo[keyName] = (docId) =>
          docId !== undefined
            ? (firestore.collection(rootName).doc(docId).collection(subName) as any)
            : firestore.collectionGroup(subName);
        return memo;
      },
      {} as {
        [key in typeof SUB_COLLECTIONS[number][2]]: SubCollec<RootDocs[key]>;
      },
    ),
  };
};
