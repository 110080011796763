import { DependencyList, useEffect, useRef, useState } from 'react';

import { IS_SSR } from 'lib/env';
import {
  loadGMaps,
  requireGmapsLibraries,
  GMaps,
  getGMaps,
  DEFAULT_GMAP_LIBS,
  geocode,
  GeocodeResult,
} from 'lib/geo';
import { createObserver, useObserver } from 'lib/utils/async';

const depsEqual = (a: DependencyList, b: DependencyList) => {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
  return true;
};

const useIsomorphicImmediate = (fn: () => void, deps: DependencyList = []): void => {
  const prev = useRef<DependencyList>();
  if (!IS_SSR && (!prev.current || !depsEqual(deps, prev.current))) {
    fn();
    prev.current = deps;
  }
};

let skipLoadDelay = false;

const loadGMapsObserver = createObserver(
  getGMaps(),
  ({ next }) => {
    if (!skipLoadDelay)
      setTimeout(() => {
        if (!skipLoadDelay && !loadGMapsObserver.value) loadGMaps().then(next);
      }, 3000);
  },
  false, // only calls the above function if this observer has any subsribers
);

const forceLoadGmaps = () => {
  if (!skipLoadDelay) {
    skipLoadDelay = true;
    loadGMaps().then(loadGMapsObserver.next);
  }
};

// TODO: handle error
export const useGMaps = (enabled = true, libraries = DEFAULT_GMAP_LIBS): GMaps | null => {
  useIsomorphicImmediate(() => {
    if (enabled) {
      requireGmapsLibraries(libraries);
      forceLoadGmaps();
    }
  }, [enabled]);

  const gMaps = useObserver(enabled ? loadGMapsObserver : null);

  return gMaps || null;
};

/**
 * Load gmaps on a delay to make initial page load faster
 */
export const useLazyGMaps = (
  enabled = true,
  libraries = DEFAULT_GMAP_LIBS,
): [gMaps: GMaps | null, load: () => void] => {
  useIsomorphicImmediate(() => {
    if (enabled) {
      requireGmapsLibraries(libraries);
    }
  }, [enabled]);

  const gMaps = useObserver(enabled ? loadGMapsObserver : null);

  return [gMaps || null, forceLoadGmaps];
};

export const useGeocode = (geocoords: { lat: number; lng: number } | null = null) => {
  const [data, setData] = useState<GeocodeResult | null>(null);

  const gMaps = useGMaps();
  useEffect(() => {
    if (!gMaps || !geocoords) return;

    geocode({
      geocoords,
    }).then(setData);
  }, [gMaps, geocoords]);

  return data;
};

// export const useBrowserGeolocation = () => {
//   const { error, latitude: lat, longitude: lng } = useGeolocation(); // `react-use`

//   return {
//     position: useMemo(
//       () =>
//         lat != null && lng != null
//           ? {
//               lat,
//               lng,
//             }
//           : null,
//       [lat, lng],
//     ),
//     error: error || null,
//   };
// };
