import _serverDb from './server';
import _browserDb from './browser';

export const serverDb = (typeof window === 'undefined' ? _serverDb : {}) as typeof _serverDb;
export const browserDb = (typeof window === 'undefined' ? {} : _browserDb) as typeof _browserDb;

const db = typeof window === 'undefined' ? _serverDb : _browserDb;

if (!!_serverDb?.C === !!_browserDb?.C || !db?.C)
  throw new Error('Exactly one of serverDb & browserDb should be imported!');

export const C = db.C;

export default db;
