import React, { createContext, useContext, useEffect, useState } from 'react';
import useSWR, { Middleware, SWRConfig, SWRConfiguration } from 'swr';
import { useLatest } from 'react-use';

import { Subject } from 'lib/utils/async';
import { request } from 'lib/request';
import Router from 'lib/router';
import { fetchPageProps } from 'lib/fetchPageProps';
import { DocumentReference, getDoc } from 'lib/utils/firestore';

export const useQuery = useSWR;

type Unknown = unknown;

type QueryStateType = 'loading' | 'loaded';

const QueryStateContext = createContext<{
  subject: Subject<{ queryKey: string; state: QueryStateType }>;
} | null>(null);

// Select and subscribe to the state of some fetch operation
export const useQueryState = (selector: (queryKey: string) => boolean) => {
  const swrState = useContext(QueryStateContext);
  if (!swrState) throw new Error('Missing QueryProvider');

  const [val, setVal] = useState<QueryStateType | null>(null);
  const selectorRef = useLatest(selector);
  useEffect(() => {
    const sub = swrState.subject.subscribe((next) => {
      if (selectorRef.current(next.queryKey)) {
        setVal(next.state);
      }
    });
    return () => sub.unsubscribe();
  }, []);

  return val;
};

const queryStateMiddleware: Middleware = (useSWRNext) => {
  return (key, fetcher, config) => {
    const swrState = useContext(QueryStateContext);

    // Handles the next middleware, or uses the `useSWR` hook if this is the last one.
    const swr = useSWRNext(key, fetcher, config);

    useEffect(() => {
      const queryKey = Array.isArray(key) ? key[0] : key;
      if (typeof queryKey === 'string') {
        swrState?.subject.next({ queryKey, state: swr.isLoading ? 'loading' : 'loaded' });
      }
    }, [key, swr.isLoading]);

    return swr;
  };
};

/**
 * The default `fetcher` for useSWR
 */
const queryFetcher = <Res extends Unknown = unknown>(
  url: string | [url: string, method: string],
) => {
  let method = 'GET';
  if (Array.isArray(url)) [url, method] = url;
  return request<Res>({ url, method });
};

export const QueryProvider: React.FC<
  SWRConfiguration & {
    children: React.ReactNode;
  }
> = ({ children, fallback, ...rest }) => {
  const [queryStateSubject] = useState(
    () => new Subject<{ queryKey: string; state: QueryStateType }>(),
  );

  return (
    <QueryStateContext.Provider value={{ subject: queryStateSubject }}>
      <SWRConfig
        value={{
          fetcher: queryFetcher,
          fallback: fallback || {},
          use: [queryStateMiddleware],
          ...rest,
        }}
      >
        {children}
      </SWRConfig>
    </QueryStateContext.Provider>
  );
};

const pagePropsFetcher = async (asPath: string) => {
  const { pageProps, error, redirectTo } = await fetchPageProps(asPath, true);
  if (redirectTo) throw new Error('Unhandled pagePropsFetcher redirect');
  if (error) throw error;

  return pageProps || {};
};

export const prefetchedPropsFetcher = async (queryKey: string) => {
  const pageProps = await pagePropsFetcher(Router.asPath);
  return pageProps.__prefetchData?.[queryKey];
};

// export const usePagePropsQuery = (options?: SWRConfiguration) => {
//   const router = useRouter();
//   const queryKey = `pageProps:${router.asPath}`;

//   const queryRes = useQuery(queryKey, () => pagePropsFetcher(router.asPath), options);

//   return {
//     ...queryRes,
//     prefetchData: queryRes.data ? queryRes.data.__prefetchData || {} : undefined,
//   };
// };

export const useDocFetcher = <Doc,>(docRef: DocumentReference<Doc> | null) => {
  return useQuery(docRef ? `db:${docRef.path}` : null, {
    fetcher: () => getDoc(docRef!),
  });
};
