import { useState, useRef, useCallback, useEffect } from 'react';

import { useWindowEvent } from 'lib/hooks/events';

const useAtTop = (topDist = 0): boolean => {
  const [atTop, setAtTop] = useState(true);

  const prevScroll = useRef(0);
  const onScroll = useCallback(() => {
    if (prevScroll.current <= topDist && window.scrollY > topDist) {
      setAtTop(false);
    } else if (prevScroll.current > topDist && window.scrollY <= topDist) {
      setAtTop(true);
    }

    prevScroll.current = window.scrollY;
  }, [topDist]);

  useWindowEvent('scroll', onScroll);

  useEffect(() => {
    if (window.scrollY > topDist) setAtTop(false);
  }, [topDist]);

  return atTop;
};

export default useAtTop;
