import { useEffect } from 'react';
import { useLatest } from 'react-use';

// TODO: use react-use/lib/useEvent

type Handler<EvtName extends string, Cb extends (...args: any[]) => void> = (
  eventName: EvtName,
  cb: Cb,
) => void;
type SimpleEmitter<
  EvtName extends string = string,
  Cb extends (...args: any[]) => void = (...args: any[]) => void,
> =
  | {
      on: Handler<EvtName, Cb>;
      off: Handler<EvtName, Cb>;
    }
  | {
      addListener: Handler<EvtName, Cb>;
      removeListener: Handler<EvtName, Cb>;
    }
  | {
      addEventListener: Handler<EvtName, Cb>;
      removeEventListener: Handler<EvtName, Cb>;
    };

export type InferEmitterCb<Emitter, EvtName extends string> = Emitter extends SimpleEmitter<
  EvtName,
  infer Cb
>
  ? Cb
  : never;

export const waitOnceForEvent = async (
  types: [emitter: SimpleEmitter<string>, eventName: string][],
  cb?: (...args: any[]) => void,
) =>
  new Promise<any[]>((resolve) => {
    const unsubs: (() => void)[] = [];

    const handler = (...args: any[]) => {
      for (const u of unsubs) u();

      cb?.(...args);
      resolve(args);
    };

    for (const [emitter, eventName] of types) {
      const [on, off] =
        'on' in emitter
          ? ['on', 'off']
          : 'addListener' in emitter
          ? ['addListener', 'removeListener']
          : ['addEventListener', 'removeEventListener'];

      (emitter as any)[on](eventName, handler);

      unsubs.push(() => (emitter as any)[off](eventName, handler));
    }
  });

export const useEvent = <EvtName extends string, Cb extends (...args: any[]) => void>(
  eventEmitter: SimpleEmitter<EvtName, Cb> | (() => SimpleEmitter<EvtName, Cb>),
  eventName: EvtName,
  cb: Cb,
): void => {
  const latestCb = useLatest<Cb>(cb);

  useEffect(() => {
    const ee = typeof eventEmitter === 'function' ? eventEmitter() : eventEmitter;

    const [on, off] =
      'on' in ee
        ? ['on', 'off']
        : 'addListener' in ee
        ? ['addListener', 'removeListener']
        : ['addEventListener', 'removeEventListener'];

    const handler = ((...args) => latestCb.current(...args)) as Cb;

    (ee as any)[on](eventName, handler);

    return () => {
      (ee as any)[off](eventName, handler);
    };
  }, [eventName, eventEmitter, latestCb]);
};

// window is undefined on initial render in next.js
const getWindow = () => window as Window;
export const useWindowEvent = <EvtName extends string, Cb extends (...args: any[]) => void>(
  eventName: EvtName,
  cb: Cb,
) => useEvent(getWindow, eventName, cb);
