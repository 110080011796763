import React, { useContext } from 'react';

const GlobalContext = React.createContext<{ reloadProps: () => Promise<void> }>({
  reloadProps: async () => undefined,
});

export const GlobalContextProvider = GlobalContext.Provider;

export const useReloadProps = () => {
  return useContext(GlobalContext).reloadProps;
};
