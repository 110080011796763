import { useState, useCallback, useEffect } from 'react';

// TODO: use react-use/lib/useLocalStorage

// Use this hook to Sync state to local storage
// so that it persists through a page refresh.
// Usage is similar to useState except we pass in
// a local storage key so that we can default to that
// value on page load instead of the specified initial value.

const useLocalStorage = <V>(
  key: string,
  initialValue: V,
): [V, (value: V | ((value: V) => V)) => void] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<V>(initialValue);

  // use useEffect to avoid SSR issues
  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) setStoredValue(JSON.parse(item));
    } catch {}
  }, [key]);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: V | ((value: V) => V)): void => {
      try {
        // Allow value to be a function so we have same API as useState
        if (value instanceof Function) {
          setStoredValue((prevValue) => {
            const newValue = value(prevValue);

            window.localStorage.setItem(key, JSON.stringify(newValue));

            return newValue;
          });
        } else {
          setStoredValue(value);
          window.localStorage.setItem(key, JSON.stringify(value));
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.error('useLocationStorage:setValue', error);
      }
    },
    [key],
  );

  return [storedValue, setValue];
};

export default useLocalStorage;
