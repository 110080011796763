import { useRef } from 'react';
import { useInterval, useIsomorphicLayoutEffect } from 'react-use';

const svgStyleHtml = {
  __html:
    '.st1,.st10,.st11,.st12,.st5,.st8{fill-rule:evenodd;clip-rule:evenodd;fill:#e0e0e0}.st10,.st11,.st12,.st5,.st8{fill:#fffffe}.st10,.st11,.st12,.st8{fill:#313430}.st10,.st11,.st12{fill:#e5556d}.st11,.st12{fill:#f5bbc5}.st12{fill:#384f50}.st13,.st14,.st15,.st18,.st19,.st23{fill-rule:evenodd;clip-rule:evenodd;fill:#248574}.st14,.st15,.st18,.st19,.st23{fill:#404440}.st15,.st18,.st19,.st23{fill:#cb0d2c}.st18,.st19,.st23{fill:#6e706b}.st19,.st23{fill:#83d1c3}.st23{fill:#9bd4d7}',
};

/**
 * `wheelAngle` is in degrees
 */
const RollingVan = ({ updateRef, x = 0, bumpy = 0, wheelAngle = 0 }) => {
  const vehicleGroup = useRef();
  const shadow = useRef();
  const chassisGroup = useRef();
  const frontWheel = useRef();
  const backWheel = useRef();
  const chassisGroup2 = useRef();

  useIsomorphicLayoutEffect(() => {
    updateRef.current = (ix, ibumpy, iwheelAngle) => {
      vehicleGroup.current.setAttribute(
        'transform',
        `scale(0.7, 0.7), translate(${500 + ix}, 100)`,
      );
      shadow.current.setAttribute('transform', `scale(${1 + ibumpy * 0.001})`);
      chassisGroup.current.setAttribute('transform', `translate(0, ${ibumpy})`);
      frontWheel.current.setAttribute('transform', `rotate(${iwheelAngle},1234.2,247)`);
      backWheel.current.setAttribute('transform', `rotate(${iwheelAngle},941.7,247)`);
      chassisGroup2.current.setAttribute('transform', `translate(0, ${ibumpy})`);
    };
    updateRef.current(x, bumpy, wheelAngle);
    return () => {
      updateRef.current = null;
    };
  }, []);

  return (
    <g
      id="vehicle-group"
      ref={vehicleGroup}
      // transform={`scale(0.7, 0.7), translate(${500 + x}, 100)`}
    >
      <path
        d="M1108.9 273.9c151.3 0 273.9 3.4 273.9 7.5s-122.7 7.4-273.9 7.4-273.9-3.3-273.9-7.4c-.1-4.1 122.6-7.5 273.9-7.5"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#38333b"
        opacity="0.5"
        transform-origin="center"
        ref={shadow}
        //  transform={`scale(${1 + bumpy * 0.001})`}
      />

      <g
        className="chassis-group"
        ref={chassisGroup}
        // transform={`translate(0, ${bumpy})`}
      >
        <path
          className="st8"
          d="M869.7 236.9h159.1c.7 6.4 6.1 11.3 12.6 11.3h128.1c2.3 2.8 5.9 4.6 9.7 4.6h129.7c6.9 0 12.7-5.7 12.7-12.7v-32.7c0-5.5-3.5-10.1-8.4-11.9v-3.9c0-6.9-5.7-12.7-12.6-12.7H869.7c-7 0-12.7 5.7-12.7 12.7v32.7c0 6.9 5.7 12.6 12.7 12.6"
        />
      </g>

      <g
        id="front-wheel"
        ref={frontWheel}
        // transform={`rotate(${wheelAngle},1234.2,247)`}
      >
        <path
          className="st14"
          d="M1234.2 208.3c21.4 0 38.7 17.4 38.7 38.7 0 21.4-17.4 38.7-38.7 38.7s-38.7-17.4-38.7-38.7c-.1-21.3 17.3-38.7 38.7-38.7"
        />
        <path
          className="st18"
          d="M1234.2 221c14.4 0 26 11.7 26 26s-11.7 26-26 26-26-11.7-26-26 11.7-26 26-26"
        />
        <path
          className="st5"
          d="M1234.2 224.3c12.6 0 22.8 10.2 22.8 22.8s-10.2 22.8-22.8 22.8c-12.5 0-22.8-10.2-22.8-22.8-.1-12.6 10.2-22.8 22.8-22.8"
        />
        <path
          className="st19"
          d="M1234.2 228.5c10.2 0 18.6 8.3 18.6 18.6s-8.3 18.6-18.6 18.6-18.6-8.3-18.6-18.6 8.4-18.6 18.6-18.6"
        />
        <path
          className="st5"
          d="M1234.2 230.6c9.1 0 16.5 7.4 16.5 16.5s-7.4 0-16.5 0-16.5 9.1-16.5 0 7.4-16.5 16.5-16.5"
        />
      </g>

      <g
        id="back-wheel"
        ref={backWheel}
        // transform={`rotate(${wheelAngle},941.7,247)`}
      >
        <path
          className="st14"
          d="M984.5 159.5c.6 0 1 .3 1 .7s-.5.7-1 .7c-.6 0-1-.3-1-.7s.5-.7 1-.7m-42.8 48.8c21.4 0 38.7 17.4 38.7 38.7 0 21.4-17.4 38.7-38.7 38.7-21.4 0-38.7-17.4-38.7-38.7 0-21.3 17.3-38.7 38.7-38.7"
        />
        <path
          className="st18"
          d="M941.7 221c14.4 0 26 11.7 26 26s-11.7 26-26 26c-14.4 0-26-11.7-26-26s11.6-26 26-26"
        />
        <path
          className="st5"
          d="M941.7 224.3c12.5 0 22.7 10.2 22.7 22.8s-10.2 22.8-22.7 22.8c-12.6 0-22.8-10.2-22.8-22.8 0-12.6 10.2-22.8 22.8-22.8"
        />
        <path
          className="st19"
          d="M941.7 228.5c10.2 0 18.6 8.3 18.6 18.6s-8.3 18.6-18.6 18.6-18.6-8.3-18.6-18.6 8.3-18.6 18.6-18.6"
        />
        <path
          className="st5"
          d="M941.7 230.6c9.1 0 16.5 7.4 16.5 16.5s-7.4 0-16.5 0c-9.2 0-16.5 9.1-16.5 0 .1-9.1 7.4-16.5 16.5-16.5"
        />
      </g>

      <g
        className="chassis-group"
        ref={chassisGroup2}
        //  transform={`translate(0, ${bumpy})`}
      >
        <path
          d="M988.4 242.6h185.8c11.5 0 17.1-25.5 21.2-29.6 7.3-7.2 69.1-6 77.5 0 8.2 5.9 8.7 27.5 12.5 27.5h50c1.1-33 .5-66.2-5-99.2 1.9-2.1 2.3-8.8-2.3-11.3-3-18.1-7.1-33-13.7-49.9-2.3-22.7-9.6-28.1-37-28.1H983.7c-35.9 0-99.9 1.7-105.2 22.5l-33.8 55.2c-5.4 1.2-8.1 4.8-8.8 10.2-8.1 1.9-8.7 41.6-7.3 78.1v23.2c17.9 6.2 44.6 4.8 64 4.8 5.5 0 12.9-29.9 23.6-40.6 6.8-6.8 46.5-5 51-.4 14.8 14.8 11.9 37.6 21.2 37.6"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#faf6b8"
        />
        <path
          className="st10"
          d="M988.4 242.6h185.8c11.5 0 17.1-25.5 21.2-29.6 7.3-7.2 69.1-6 77.5 0 8.2 5.9 8.7 27.5 12.5 27.5h50c1.1-33 .5-66.2-5-99.2l-494.3-1.2c-8.1 1.9-8.7 41.6-7.3 78.1v23.1c17.9 6.2 44.6 4.8 64 4.8 5.5 0 12.9-29.9 23.6-40.6 6.8-6.8 46.5-5 51-.4 14.6 14.7 11.7 37.5 21 37.5"
        />
        <path
          className="st11"
          d="M1330.4 141.3c1.9-2.1 2.3-8.8-2.3-11.3l-483.3-.2c-5.4 1.2-8.1 4.8-8.8 10.2l494.4 1.3"
        />
        <path
          className="st10"
          d="M1329 138.6c1.9-1 2.6-4.5-1-5.9l-483.3-.1c-5.4.6-7.1 2.3-7.6 5.3l491.9.7"
        />
        <path
          className="st12"
          d="M878.5 74.7l-33.8 55.2c4.3 2.2 13.9 2.9 16.3-2 7.4-14.7 14.7-29.2 19.8-44.7 1-2.8-.2-6-2.3-8.5"
        />
        <path
          className="st13"
          d="M877.1 76.9l-31.3 51.2c3.2 1.6 11.3 2.4 13.1-1.3 7.2-14.5 14.6-28.9 19.7-44.4.4-1-.3-4.2-1.5-5.5"
        />
        <path
          className="st14"
          d="M1293.6 116.3h12.6c.6 0 1.1.5 1.2 1l.2 1.2c.1.6-.2 1-.9 1h-12.6c-.6 0-1.1-.5-1.2-1l-.2-1.2c0-.5.4-1 .9-1zm-.5-4.6h12.6c.6 0 1.1.5 1.2 1l.2 1.2c.1.6-.3 1-.9 1h-12.6c-.6 0-1.1-.5-1.3-1l-.2-1.2c0-.6.3-1 1-1zm-.9-4.6h12.6c.6 0 1.1.5 1.2 1l.2 1.3c.1.6-.2 1-.9 1h-12.6c-.6 0-1.1-.5-1.3-1l-.2-1.3c0-.6.4-1 1-1zm-1.2-4.7h12.6c.6 0 1.1.5 1.3 1l.2 1.3c.1.6-.2 1-.8 1h-12.6c-.6 0-1.1-.5-1.3-1l-.2-1.3c-.1-.5.2-1 .8-1zm-1.3-4.6h12.6c.6 0 1.1.5 1.2 1l.2 1.2c.1.6-.2 1-.9 1h-12.6c-.6 0-1.1-.5-1.2-1l-.2-1.2c-.1-.6.3-1 .9-1zm-1.3-4.7h12.6c.6 0 1.1.5 1.2 1l.2 1.2c.1.6-.2 1-.9 1h-12.6c-.6 0-1.1-.5-1.2-1l-.2-1.2c0-.5.4-1 .9-1zm-1.3-4.6h12.6c.6 0 1.1.5 1.2 1l.2 1.3c.1.6-.2 1-.9 1h-12.6c-.6 0-1.1-.5-1.2-1l-.2-1.3c0-.5.4-1 .9-1zm-1.4-4.8c4.7 0 13.5.1 14.1 2.3.2.6-.2 1-.9 1h-12.6c-.6 0-1.1-.5-1.2-1l-.2-1.2c-.2-.7.1-1.1.8-1.1zm8.3 37.3h12.6c.6 0 1.1.5 1.2 1l.2 1.2c.1.6-.2 1-.9 1h-12.6c-.6 0-1.1-.5-1.2-1l-.2-1.2c-.1-.6.3-1 .9-1z"
        />
        <path
          className="st15"
          d="M1334.8 180c1.6.5 3.7 1.4 4 3.1 1.2 5.2 1.3 29.8.7 32.8-.3 1.6-1.7 3.2-3.8 4.5-1.9-3.4-2.7-32.2-.9-40.4"
        />
        <path
          d="M1311.7 227.1h24c4.2 0 7.6 3.5 7.6 7.6v5.1c0 4.2-3.4 7.6-7.6 7.6h-24c-4.2 0-7.6-3.5-7.6-7.6v-5.1c.1-4.2 3.5-7.6 7.6-7.6m-483 0h8.8c4.2 0 7.6 3.5 7.6 7.6v5.1c0 4.2-3.4 7.6-7.6 7.6h-8.8c-4.2 0-7.6-3.5-7.6-7.6v-5.1c0-4.2 3.4-7.6 7.6-7.6"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#732b37"
        />
        <path
          className="st15"
          d="M833.6 147.3c-1.6.2-3.7.5-4.1 1-1.2 1.8-1.3 10.7-.7 11.7.3.6 1.7 1.1 3.8 1.6 2-1.1 2.7-11.4 1-14.3"
        />
        <path className="st8" d="M832 173c-7.8 1.4-7.9 23.6-1 26.4 2-2.2 2.7-21 1-26.4" />
        <path
          className="st11"
          d="M1314.4 80.2c-2.3-22.7-9.6-28.1-37-28.1H983.7c-35.9 0-99.9 1.7-105.2 22.5l435.9 5.6"
        />
        <path
          className="st10"
          d="M889.8 66.7l422.2 5.8c-3.7-11.8-11.6-16.1-20.2-16.2-134-1.8-368.1-8.1-402 10.4"
        />
        <path
          d="M973.9 154.6c4.3 0 7.9 2.5 7.9 5.5 0 3.1-3.5 5.6-7.9 5.6-4.3 0-7.9-2.5-7.9-5.6 0-3 3.5-5.5 7.9-5.5"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#d63d57"
        />
        <path
          className="st5"
          d="M964.5 159l21.7-1.2c1.6-.1 1.6 4.8 0 4.7l-21.7-1.2c-.7 0-.7-2.3 0-2.3"
        />

        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#9c7245"
          d="M973.5 30.9h303.7v9.6H973.5z"
        />
        <path
          d="M988 80.7h17v-4.4h-17v4.4zm144.6 0h16.9v-4.4h-16.9v4.4zm111.2 0h17v-4.4h-17v4.4z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#535752"
        />
        <path
          className="st14"
          d="M1138.3 76.3h5.6V30.9h-5.6v45.4zm-144.6 0h5.6V30.9h-5.6v45.4zm255.8 0h5.6V30.9h-5.6v45.4z"
        />
        <path
          d="M849.9 236.1h36.8c8.1 0 17.4-41.1 34.2-41.1h67.9v-54.6c.4-3.3-.1-7.6-1.7-10.5V81.4H889c-8.9 11-16.9 35.2-21.5 49-4.1 2-8.8 5.5-10.3 10-6.7 19.1-14.2 76.8-7.3 95.7zm5.7-96.2c1.6-4.6 6.3-8.5 10.5-10.6 4.5-13.2 13.3-39.1 22.2-49.5h100.6v49.8c1.5 3.1 2.1 7.4 1.7 10.7v56.2H921c-15.8 0-24.4 41.1-34.2 41.1h-37.9c-7.8-19.3-.3-77.6 6.7-97.7zm283.9-59.3l1.2 49.4.6 10.9v101.7h-1.7V140.9l-.6-10.9-1.2-49.4h1.7z"
          fill="none"
        />

        <path
          className="st12"
          d="M896.2 84.9h79.2c3.1 0 5.6 2.5 5.6 6v28.9c0 4-2.1 7.4-6.3 7.4l-96.1-.1c-3.2 0-5.6-4.3-4.3-7.2l14.4-30.1c1.4-2.7 4.5-4.9 7.5-4.9"
        />
        <path
          className="st13"
          d="M896.2 87.2c-2.1 0-4.5 1.8-5.4 3.6l-14.4 30.1c-.6 1.3.6 3.7 2.2 3.7l96.1.1c2.9 0 3.9-2.4 3.9-5V90.8c0-1.9-1.2-3.6-3.3-3.6h-79.1"
        />
        <path
          className="st23"
          d="M947.3 87.2h15.8l-21.6 37.5h-15.8l21.6-37.5zm18.1 0h2.5l-21.6 37.5h-2.5l21.6-37.5zm-48.7 0h6.4l-21.6 37.5H895l21.7-37.5z"
        />
        <path
          className="st12"
          d="M1008.3 84.9h115.4c3.1 0 5.6 2.5 5.6 6v28.9c0 4-2.1 7.4-6.3 7.4l-114.4-.1c-3.1 0-5.2-2.6-5.2-7.2V89.8c.1-2.9 1.9-4.9 4.9-4.9"
        />
        <path
          className="st13"
          d="M1008.3 87.2c-1.7 0-2.4 1-2.4 2.7V120c0 1.9.5 4.7 2.9 4.7l114.4.1c2.9 0 3.9-2.4 3.9-5V90.9c0-1.9-1.2-3.6-3.3-3.6l-115.5-.1"
        />
        <path
          className="st23"
          d="M1093.2 87.2h15.8l-21.6 37.5h-15.9l21.7-37.5zm18 0h2.5l-21.6 37.5h-2.5l21.6-37.5zm-48.7 0h6.5l-21.7 37.5h-6.4l21.6-37.5z"
        />
        <path
          className="st12"
          d="M1151.2 84.9h115.4c5.8 0 11.3 6.3 12.4 13.7l3.3 21.2c.6 3.9-2 7.4-6.3 7.4l-124.4-.1c-3.2 0-5.3-2.6-5.3-7.2V89.8c.2-2.9 1.9-4.9 4.9-4.9"
        />
        <path
          className="st13"
          d="M1151.2 87.2c-1.7 0-2.5 1-2.5 2.7V120c0 1.9.5 4.7 2.9 4.7l124.4.1c2.7 0 4.3-2 3.9-4.7l-3.3-21.2c-.5-3.1-1.8-6.1-3.9-8.4-1.6-1.8-3.8-3.3-6.2-3.3h-115.3"
        />
        <path
          className="st23"
          d="M1237.6 87.2h15.8l-21.6 37.5H1216l21.6-37.5zm18 0h2.5l-21.6 37.5h-2.5l21.6-37.5zm-48.6 0h6.4l-21.6 37.5h-6.4l21.6-37.5z"
        />
        <path
          className="st18"
          d="M1046.2 30.9h81.8c4.7 0 8.4-3.8 8.4-8.4V8.4c0-4.7-3.8-8.4-8.4-8.4h-81.8c-4.7 0-8.4 3.8-8.4 8.4v14.1c0 4.6 3.8 8.4 8.4 8.4"
        />
        <path className="st5" d="M1037.8 17.8h98.8V16h-98.8v1.8zm0-2.9h98.8v-1.8h-98.8v1.8z" />
        <path
          d="M1145.7 30.9h81.8c4.7 0 8.4-3.8 8.4-8.4V8.4c0-4.7-3.8-8.4-8.4-8.4h-81.8c-4.7 0-8.4 3.8-8.4 8.4v14.1c0 4.6 3.8 8.4 8.4 8.4"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#d6b6cc"
        />
      </g>

      <style jsx>{`
        #vehicle-group,
        .chassis-group {
          transition: transform 0.1s linear;
        }
      `}</style>
    </g>
  );
};

const DEG_PER_RAD = 180 / Math.PI;

export const LoadingCar = ({ className, style }) => {
  const updateRef = useRef();
  const prevRad = useRef(0);

  useInterval(() => {
    if (!updateRef.current) return;

    const rad = (prevRad.current += 0.7);

    // https://www.desmos.com/calculator/1n1oii1tdp
    const bumpy = Math.sin(rad) * 2 + Math.sin((rad + 0.5) * 1) * 2;
    const wheelAngle = -DEG_PER_RAD * rad;

    updateRef.current(0, bumpy, wheelAngle);
  }, 60);

  return (
    <svg
      aria-label="Rolling car loading indicator"
      className={className}
      style={style}
      version="1.1"
      x={0}
      y={0}
      viewBox="860 0 530 350"
      xmlSpace="preserve"
    >
      <style dangerouslySetInnerHTML={svgStyleHtml} />
      <RollingVan updateRef={updateRef} />
    </svg>
  );
};

// export const ScrollingCar = ({ className, style }) => {
//   const updateRef = useRef();
//   const isReduceMotion = useReducedMotion();

//   useWindowEvent('scroll', () => {
//     if (isReduceMotion || !updateRef.current) return;

//     const x = -document.documentElement.scrollTop;
//     const bumpy = Math.sin(x * 0.05) * 4;
//     const wheelAngle = x;

//     updateRef.current(x, bumpy, wheelAngle);
//   });

//   return (
//     <svg
//       aria-hidden="true"
//       className={className}
//       style={style}
//       version="1.1"
//       x={0}
//       y={0}
//       viewBox="0 0 1406.8 387.6"
//       xmlSpace="preserve"
//     >
//       <style dangerouslySetInnerHTML={svgStyleHtml} />
//       <path
//         d="M0 79C178.9 55.2 223.2-27.2 296.6-27.2c60.4 0 138.1 63.7 206.4 65.5 103 2.6 230.5-73.5 318.6-73.5 68.3 0 204.4 112.1 419.8 112.1 59.2 0 121.1-26.6 165.2-52.1v268.6H0V79"
//         fillRule="evenodd"
//         clipRule="evenodd"
//         fill="#c2c2c2"
//         transform="translate(0 95)"
//       />
//       <path
//         className="st1"
//         d="M821.6-35.2c68.3 0 204.5 112.1 419.8 112.1-147.3 24.7-442.9-11.7-477.6-69-9.1-15 27-35.3 57.8-43.1zm-525 8c60.4 0 138.1 63.7 206.4 65.5C355.7 63 238.8 41.5 238.8 16c0-17.7 26.9-35.4 57.8-43.2z"
//         transform="translate(0 95)"
//       />
//       <path
//         d="M0 95.4C138.4 2.8 172.3 95.3 230.8 77 593.2-36.2 632 60.5 790.6 98.4c296-40.5 407.5-145.2 616.1-22.8v218H0V95.4"
//         fillRule="evenodd"
//         clipRule="evenodd"
//         fill="#a3a3a3"
//         transform="translate(0 95)"
//       />
//       <path
//         className="st1"
//         d="M101.3 53.9C161.4 49 190.2 89.7 230.8 77c-52.6 19.9-122.9 34.8-155.9 19.9-16.1-7.3-10.5-34.9 26.4-43zm1142.3-34.6c50.8 4.5 102.9 20.9 163.1 56.2V100c-52.6 19.9-327.5 13.7-211.4-39.7 32.4-14.9-18.8-29.4 48.3-41zm-730.2 4.4c122.2 1 174.6 50.1 277.2 74.6-31.2 5.1-169.9 38.3-244 4.7-66.9-30.2-129.2-67.2-33.2-79.3z"
//         transform="translate(0 95)"
//       />
//       <path
//         d="M0 121.6c112.5 0 205.3-38.7 295.4-38.7 95.1 0 250.4 59.2 396 59.2 119.8-.1 208.8-58 307.6-59.4 108.7-1.4 204.3 49.9 407.7 49.9l.1 161H.1l-.1-172"
//         fillRule="evenodd"
//         clipRule="evenodd"
//         fill="#666"
//         transform="translate(0 95)"
//       />
//       <path
//         d="M.1 293.5h1406.6V142H.1v151.5z"
//         fillRule="evenodd"
//         clipRule="evenodd"
//         fill="#24242d"
//         transform="translate(0 95)"
//       />
//       <path
//         d="M123 208.2h52.9v-5.7H123v5.7zm123.1 0H299v-5.7h-52.9v5.7zm123.1 0h52.9v-5.7h-52.9v5.7zm123.1 0h52.9v-5.7h-52.9v5.7zm123.1 0h52.9v-5.7h-52.9v5.7zm123.1 0h52.9v-5.7h-52.9v5.7zm123 0h52.9v-5.7h-52.9v5.7zm123.1 0h52.9v-5.7h-52.9v5.7zm123 0h52.9v-5.7h-52.9v5.7zm123.1 0h52.9v-5.7h-52.9v5.7zm123.1 0h52.9v-5.7h-52.9v5.7zM0 208.2h52.9v-5.7H0v5.7z"
//         fillRule="evenodd"
//         clipRule="evenodd"
//         fill="#ffba49"
//         transform="translate(0 95)"
//       />
//       <RollingVan updateRef={updateRef} />
//     </svg>
//   );
// };
