import { getUser, onAuthStateChanged } from 'lib/auth';
import type { NotificationType } from 'lib/constants/notifications';
import { browserDb } from 'lib/db/shared';
import { trackError } from 'lib/tracking';
import { createObserver, useObserver } from 'lib/utils/async';
import { snapToDocs } from 'lib/utils/firestore';
import { now } from 'lib/utils/time';
import type { NotificationDoc, WithId } from 'schemas/types';

const { C, batch } = browserDb;

const GUEST_NOTIF_TYPES = new Set<NotificationType>([
  'booking_confirmed_guest',
  'booking_canceled_by_host_guest',
  'booking_canceled_by_guest_guest',
  'booking_refund_issued',
  'booking_dates_changed_guest',
  'booking_denied_guest',
  'booking_receipt_guest',
  'booking_ends_host',
  'booking_ends_guest',
]);
const HOST_NOTIF_TYPES = new Set<NotificationType>([
  'booking_confirmed_host',
  'new_booking_request_host',
  'booking_dates_changed_host',
  'booking_denied_host',
  'booking_canceled_by_host_host',
  'booking_canceled_by_guest_host',
]);

const markAsRead = async (notifs: WithId<NotificationDoc>[]) => {
  if (notifs.length === 0) return;
  const authUser = getUser();
  if (!authUser) return;

  const writeBatch = batch();

  const collec = C.notifications(authUser.uid);

  const read_at = now();

  for (const { id } of notifs) {
    writeBatch.update(collec.doc(id), {
      read_at,
    });
  }

  await writeBatch.commit();
};

const notifsToProps = (notifications: WithId<NotificationDoc>[] | null) => {
  const notifs = notifications || [];

  const messages = notifs.filter((n) => n.type === 'message_received');
  const guestNotifs = notifs.filter((n) => GUEST_NOTIF_TYPES.has(n.type));
  const hostNotifs = notifs.filter((n) => HOST_NOTIF_TYPES.has(n.type));

  return {
    markMessagesRead: () => markAsRead(messages),
    markGuestNotifsRead: () => markAsRead(guestNotifs),
    markHostNotifsRead: () => markAsRead(hostNotifs),
    messageCount: messages.length,
    guestNotifCount: guestNotifs.length,
    hostNotifCount: hostNotifs.length,
  };
};

type NotifState = ReturnType<typeof notifsToProps>;

const notificationsObserver = createObserver<NotifState>(
  notifsToProps(null),
  () => {
    let unsubData: (() => void) | undefined;

    const listenToNotifications = (userId: string) => {
      return C.notifications(userId)
        .where('read_at', '==', null)
        .onSnapshot(
          (snap) => {
            notificationsObserver.next(notifsToProps(snapToDocs(snap)));
          },
          (err) => {
            trackError(err);
          },
        );
    };

    const authUser = getUser();
    if (authUser) unsubData = listenToNotifications(authUser.uid);

    const unsubAuth = onAuthStateChanged((user) => {
      unsubData?.();
      unsubData = user ? listenToNotifications(user.uid) : undefined;
    });

    return () => {
      unsubData?.();
      unsubAuth();
    };
  },
  true,
);

export const useNotifications = () => useObserver(notificationsObserver)!;
