import * as _ from 'lodash-es';
import type admin from 'firebase-admin';
import type firebase from 'firebase/compat/app';
import type { UpdateData } from 'firebase/firestore';

import { throwNotFound, NOT_FOUND, createError, throwForbidden } from 'lib/utils/errors';
import type { WithId } from 'schemas/types';

export type DocumentData = Record<string, unknown>;
export type DocumentReference<T = DocumentData> =
  | admin.firestore.DocumentReference<T>
  | firebase.firestore.DocumentReference<T>;
export type Query<T = DocumentData> = admin.firestore.Query<T> | firebase.firestore.Query<T>;
export type DocumentSnapshot<T = DocumentData> =
  | admin.firestore.DocumentSnapshot<T>
  | firebase.firestore.DocumentSnapshot<T>;
export type QuerySnapshot<T = DocumentData> =
  | admin.firestore.QuerySnapshot<T>
  | firebase.firestore.QuerySnapshot<T>;
export type CollectionReference<T = DocumentData> =
  | admin.firestore.CollectionReference<T>
  | firebase.firestore.CollectionReference<T>;

const DEFAULT_TIMEOUT = 16000;
const RETRY_DELAY = 400;

export const docSnapData = <Doc>(doc: DocumentSnapshot<Doc>): WithId<Doc> => {
  const data = doc.data() as WithId<Doc>;
  data.id = doc.id;
  if (doc.ref.parent.parent?.parent) {
    data.parent_id = doc.ref.parent.parent.id;
    data.parent_type = doc.ref.parent.parent.parent.id;
  }
  return data;
};

export const waitForDoc = async <Doc>(
  ref: DocumentReference<Doc>,
  { timeout = DEFAULT_TIMEOUT, fail = true }: { timeout?: number; fail?: boolean } = {},
): Promise<DocumentSnapshot<Doc> | null> => {
  try {
    const doc = await ref.get({ source: 'server' });
    if (doc.exists) {
      return doc;
    } else {
      return await new Promise<DocumentSnapshot<Doc> | null>((resolve, reject) => {
        let timeoutTimer: ReturnType<typeof setTimeout>;

        const unsub = (ref as firebase.firestore.DocumentReference<Doc>).onSnapshot(
          (docSnap) => {
            if (docSnap.exists) {
              clearTimeout(timeoutTimer);
              unsub();
              resolve(docSnap);
            }
          },
          (err) => {
            clearTimeout(timeoutTimer);
            unsub();
            reject(err);
          },
        );

        timeoutTimer = setTimeout(() => {
          unsub();

          reject(fail ? createError(NOT_FOUND) : null);
        }, timeout);
      });
    }
  } catch (err) {
    if (err.code === 'permission-denied') {
      for (let t = 0; t < timeout; t += RETRY_DELAY) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));

        try {
          const doc = await ref.get({ source: 'server' });
          if (doc.exists) return doc;
        } catch (err2) {
          if (err2.code !== 'permission-denied') throw err2;
        }
      }

      return fail ? throwNotFound() : null;
    } else {
      throw err;
    }
  }
};

export const snapToDocs = <Doc>(snap: QuerySnapshot<Doc>): WithId<Doc>[] =>
  (snap as firebase.firestore.QuerySnapshot<Doc>).docs.map(docSnapData);

export const getDocs = <Doc>(query: Query<Doc>): Promise<WithId<Doc>[]> =>
  (query as firebase.firestore.Query<Doc>).get().then(snapToDocs);

type ArrayFromObj<Obj extends Record<number, any>> = { length: number } & Obj;

export const getAllDocs = async <Docs extends Record<string, unknown>[]>(
  ...docs: ArrayFromObj<{ [i in keyof Docs]: DocumentReference<Docs[i]> }>
): Promise<ArrayFromObj<{ [i in keyof Docs]: WithId<Docs[i]> | null }>> => {
  type Res = ArrayFromObj<{ [i in keyof Docs]: WithId<Docs[i]> | null }>;

  if (docs.length === 0) return [] as any[] as Res;

  const firestore = docs[0]?.firestore;

  const res =
    firestore && 'getAll' in firestore
      ? await firestore.getAll(...(docs as admin.firestore.DocumentReference[]))
      : await Promise.all((docs as firebase.firestore.DocumentReference[]).map((doc) => doc.get()));

  return (res as DocumentSnapshot<any>[]).map((docSnap) =>
    docSnap.exists ? docSnapData(docSnap) : null,
  ) as Res;
};

type FailDoc<Doc, Fail> = Fail extends false ? WithId<Doc> | null : WithId<Doc>;

export const getDoc = async <Doc, Fail extends boolean | undefined = true>(
  ref: DocumentReference<Doc>,
  {
    wait = false,
    fail = true,
    cache = true,
    timeout = DEFAULT_TIMEOUT,
  }: {
    wait?: boolean;
    fail?: Fail;
    cache?: boolean;
    timeout?: number;
  } = {},
): Promise<FailDoc<Doc, Fail>> => {
  let doc: DocumentSnapshot<Doc> | undefined | null;
  try {
    doc = wait
      ? await waitForDoc(ref, { timeout, fail: false })
      : await ref.get({ source: cache ? 'default' : 'server' });
  } catch (err) {
    if (err.code === 'permission-denied') {
      if (fail) throwForbidden(`Cannot access resource: ${ref.path}`);
      else return null as FailDoc<Doc, Fail>;
    } else throw err;
  }

  if (!doc || !doc.exists) {
    if (fail) throwNotFound(`Cannot find resource: ${ref.path}`);
    return null as FailDoc<Doc, Fail>;
  } else {
    return docSnapData(doc) as FailDoc<Doc, Fail>;
  }
};

export const getDocsAsMap = async <Doc, ID extends string>(
  collec: CollectionReference<Doc>,
  ...ids: ID[]
): Promise<Record<ID, DocumentSnapshot<Doc>>> => {
  const refs = _.uniq(ids).map((id) => collec.doc(id));

  const docs = refs.length
    ? 'getAll' in collec.firestore
      ? await collec.firestore.getAll(...(refs as admin.firestore.DocumentReference[]))
      : await Promise.all(
          (refs as firebase.firestore.DocumentReference<Doc>[]).map((ref) => ref.get()),
        )
    : [];

  const map = {} as Record<ID, DocumentSnapshot<Doc>>;
  for (const doc of docs) {
    map[doc.id as ID] = doc as DocumentSnapshot<Doc>;
  }
  return map;
};

type MaybePromise<T> = T | Promise<T>;

export const updateDoc = async <
  Doc extends Record<string, unknown>,
  CreateIfNotExists extends boolean = false,
>(
  ref: DocumentReference<Doc>,
  updater:
    | Partial<Doc>
    | ((
        prevData: CreateIfNotExists extends true ? Doc | undefined : Doc,
      ) => MaybePromise<Partial<Doc> | undefined>),
  createIfNotExists: CreateIfNotExists = false as CreateIfNotExists,
): Promise<void> => {
  const ref_ = ref as firebase.firestore.DocumentReference<Doc>;

  if (typeof updater === 'function') {
    await ref_.firestore.runTransaction(async (trans) => {
      const doc = await trans.get(ref_);
      if (!doc.exists && !createIfNotExists) throwNotFound(`Cannot find resource: ${ref.path}`);

      const newData = await updater(
        (doc.exists ? docSnapData(doc) : undefined) as CreateIfNotExists extends true
          ? Doc | undefined
          : Doc,
      );

      if (newData && !_.isEmpty(newData)) {
        if (createIfNotExists) return trans.set(ref_, newData, { merge: true });
        else return trans.update(ref_, newData);
      }
    });
  } else if (createIfNotExists) await ref.set(updater, { merge: true });
  else await ref.update(updater as UpdateData<Doc>);
};

export const queryExists = async (collec: Query | CollectionReference): Promise<boolean> => {
  if ('select' in collec) collec = collec.select();
  const snap = await collec.limit(1).get();
  return !snap.empty;
};

export const getDocsByField = async <Doc>(
  collec: Query<Doc> | CollectionReference<Doc>,
  foreignKey: string,
  values: any[],
): Promise<WithId<Doc>[]> => {
  const docs = _.flatten(
    await Promise.all(
      _.chunk(values, 10).map((valuesChunk) =>
        getDocs(collec.where(foreignKey, 'in', valuesChunk)),
      ),
    ),
  );

  return docs;
};

const addReverseRelation = async <
  Obj extends { id: string },
  Doc,
  Id extends string,
  Key extends string,
  Multiple extends boolean | undefined,
>(
  arr: Obj[],
  collec: CollectionReference<Doc>,
  foreignKey: Id,
  key: Key,
  fallback?: (docId: string) => any,
  multiple?: Multiple,
): Promise<(Obj & { [k in Key]?: Multiple extends true ? WithId<Doc>[] : WithId<Doc> })[]> => {
  const docs = await getDocsByField(
    collec,
    foreignKey,
    arr.map((a) => a.id),
  );

  const docMap = _.transform(
    docs,
    (acc, curr) => {
      // @ts-expect-error unknown key
      const foreignId = curr[foreignKey];
      if (typeof foreignId === 'string') (acc[foreignId] ||= []).push(curr);
    },
    {} as Record<string, Doc[] | undefined>,
  );

  for (const el of arr) {
    const thisDocs = docMap[el.id];
    if (!multiple && !thisDocs && fallback) {
      // @ts-expect-error unknown key
      el[key] = fallback(docId);
    } else if (thisDocs) {
      // @ts-expect-error unknown key
      el[key] = multiple ? thisDocs : thisDocs[0];
    }
  }

  return arr as any[];
};

const addRelation = async <
  Obj,
  Doc,
  Id extends string | ((item: Obj) => string),
  Key extends string,
>(
  arr: Obj[],
  collec: CollectionReference<Doc>,
  id: Id,
  key: Key,
  fallback?: (docId: string) => any,
): Promise<(Obj & { [k in Key]?: WithId<Doc> })[]> => {
  const getId: (item: Obj) => unknown =
    typeof id === 'string' && id
      ? (item: Obj) => _.get(item, id)
      : _.isFunction(id)
      ? id
      : () => null;

  const docMap = await getDocsAsMap(
    collec,
    ...arr.map(getId).filter((a): a is string => typeof a === 'string' && a.length > 0),
  );

  for (const el of arr) {
    const docId = getId(el);
    if (typeof docId !== 'string' || docId.length === 0) continue;

    const doc = docMap[docId];
    if (doc?.exists) {
      // @ts-expect-error unknown key
      el[key] = docSnapData(doc);
    } else if (fallback) {
      // @ts-expect-error unknown key
      el[key] = fallback(docId);
    }
  }

  return arr as (Obj & { [k in Key]: WithId<Doc> })[];
};

export const addRelations = async <
  Id extends string,
  Obj extends Id extends `reverse:${string}` | `reverse-many:${string}`
    ? { id: string }
    : Record<string, any>,
  Incl extends {
    [key in string]: [collec: CollectionReference<any>, id: Id, fallback?: (docId: string) => any];
  },
>(
  arr: Obj[],
  includes: Incl,
): Promise<
  (Obj & {
    [key in keyof Incl]: Incl[key][0] extends CollectionReference<infer Doc>
      ? Id extends `reverse-many:${string}`
        ? WithId<Doc>[] | undefined
        : Id extends `reverse:${string}`
        ? WithId<Doc> | undefined
        : WithId<Doc>
      : never;
  })[]
> => {
  await Promise.all(
    Object.keys(includes).map((key) => {
      const [collec, id, fallback] = includes[key];

      const reverseMany = id.startsWith('reverse-many:');
      if (reverseMany || id.startsWith('reverse:')) {
        return addReverseRelation(
          arr as any[],
          collec,
          id.replace('reverse:', ''),
          key,
          fallback,
          reverseMany,
        );
      } else {
        return addRelation(arr, collec, id, key, fallback);
      }
    }),
  );

  return arr as any[];
};

export const cloneQuery = <Doc>(
  query: admin.firestore.CollectionReference<Doc> | admin.firestore.Query<Doc>,
) => {
  const offset = (query as any)._queryOptions.offset;

  const newQuery = query.offset(904343937);

  (newQuery as any)._queryOptions.offset = offset;

  return newQuery;
};

export const withoutQueryPagination = <Doc>(
  query: admin.firestore.CollectionReference<Doc> | admin.firestore.Query<Doc>,
) => {
  const newQuery = cloneQuery(query);

  Object.assign((newQuery as any)._queryOptions, {
    fieldOrders: [],
    startAt: undefined,
    endAt: undefined,
    limit: undefined,
    limitType: undefined,
    offset: undefined,
  });

  return newQuery;
};

// only unique on filters, NOT pagination (limit,offset,startAt,orderBy,etc.)
export const getUniqueQueryKey = <Doc>(
  query: admin.firestore.CollectionReference<Doc> | admin.firestore.Query<Doc>,
) => {
  const { collectionId, filters } = (query as any)._queryOptions as {
    collectionId: string;
    filters: {
      field: admin.firestore.FieldPath & { toString(): string };
      op: string;
      value: any;
    }[];
  };

  const uniqueKey = `${collectionId}:${filters
    .map((f) => `${f.field}-${f.op}-${encodeURIComponent(JSON.stringify(f.value))}`)
    .join(',')}`;
  return uniqueKey;
};
