import { decodeQuery, encodeQuery } from 'lib/createRoutes.mjs';
import { HOST, UNIQUE_BUILD_HOST } from 'lib/env';

export { decodeQuery, encodeQuery };

export const absUrl = (url: string, hostname = HOST): string | undefined => {
  if (typeof url !== 'string' || url.length === 0) return undefined;

  if (!url) return undefined;
  if (url.startsWith('//')) return `https:${url}`;
  if (url.startsWith('/')) return `${hostname}${url}`;
  return url;
};

export const relativeUrl = (url: string, enforceSameOrigin?: boolean): string | null => {
  if (typeof url !== 'string' || url.length === 0) return null;

  try {
    const parsedUrl = new URL(url, HOST);
    if (parsedUrl.pathname && (!enforceSameOrigin || HOST === parsedUrl.origin)) {
      return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
    }
  } catch {}
  return null;
};

export const parseSameOriginUrl = (url: string): string | null => {
  const relative = relativeUrl(url, true);
  return relative != null ? HOST + relative : null;
};

const PLACEHOLDER_HOST = 'http://a.b';
/**
 * Appends the `query` to the given `url`. Maintains the url's hostname (or lack of) unless a custom `hostname` is provided
 */
export const addUrlQuery = (url: string, query: Record<string, any>, hostname?: string) => {
  try {
    const p = new URL(url, hostname || PLACEHOLDER_HOST);

    return (
      (p.origin !== PLACEHOLDER_HOST ? p.origin : '') +
      p.pathname +
      encodeQuery({ ...decodeQuery(p.search || undefined), ...(query || {}) }, true) +
      p.hash
    );
  } catch {
    return url;
  }
};

export const urlPathname = (url: string) => {
  try {
    const { pathname } = new URL(url, PLACEHOLDER_HOST);
    if (pathname) return pathname;
  } catch {}
  return url;
};

export const isExternalUrl = (url: string): boolean => {
  if (!url || url.startsWith('/')) return false;

  try {
    const parsedUrl = new URL(url, HOST);
    return ![
      HOST,
      UNIQUE_BUILD_HOST,
      typeof window !== 'undefined' && window.location.origin,
    ].includes(parsedUrl.origin);
  } catch {}

  return false;
};
