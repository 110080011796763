/* eslint-disable import/no-webpack-loader-syntax */

export { default as apple } from '!!@svgr/webpack?dimensions=false!images/icons/social/apple.svg';
export { default as facebook } from '!!@svgr/webpack?dimensions=false!images/icons/social/facebook.svg';
export { default as google } from '!!@svgr/webpack?dimensions=false!images/icons/social/google.svg';
export { default as hackernews } from '!!@svgr/webpack?dimensions=false!images/icons/social/hackernews.svg';
export { default as instagram } from '!!@svgr/webpack?dimensions=false!images/icons/social/instagram.svg';
export { default as linkedin } from '!!@svgr/webpack?dimensions=false!images/icons/social/linkedin.svg';
export { default as reddit } from '!!@svgr/webpack?dimensions=false!images/icons/social/reddit.svg';
export { default as twitter } from '!!@svgr/webpack?dimensions=false!images/icons/social/twitter.svg';
export { default as youtube } from '!!@svgr/webpack?dimensions=false!images/icons/social/youtube.svg';
