import { Fragment } from 'react';

import * as iconMap from './icons';

const SocialIcon = ({
  icon,
  component: Component = Fragment,
  size = 16,
  iconStyle = {},
  ...rest
}) => {
  const Icon = iconMap[icon];
  if (!Icon) return null;

  return (
    <Component {...rest}>
      <span className="sr-only">{icon}</span>
      <Icon style={{ width: size, height: size, ...iconStyle }} />
    </Component>
  );
};

export default SocialIcon;
