import * as _ from 'lodash-es';
import { useEffect } from 'react';
import Router, { useRouter, RouterEvent } from 'next/router';
import { useLatest } from 'react-use';

import { InferEmitterCb, useEvent } from 'lib/hooks/events';

export { useRouter };

export const useRouterEvent = <EvtName extends RouterEvent>(
  eventName: EvtName,
  cb: InferEmitterCb<typeof Router.events, EvtName>,
): void => {
  useEvent(Router.events, eventName, cb);
};

type HijackCb = () => boolean;

export const backButtonHijackers: HijackCb[] = [];

/**
 * Only works in mobile app
 */
export const useHijackBrowserBack = (hijackCb: HijackCb | null) => {
  const enabled = !!hijackCb;

  const latestCb = useLatest(hijackCb);
  useEffect(() => {
    if (!enabled) return;

    const cb = () => {
      return !!latestCb.current?.();
    };

    backButtonHijackers.push(cb);

    return () => {
      _.pull(backButtonHijackers, cb); // mutates array
    };
  }, [enabled]);
};
