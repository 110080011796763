export const parameterize = (str: string): string =>
  (str || '')
    .trim()
    .replace(/[^a-zA-Z0-9\s._-]/g, '')
    .replace(
      /^[a-zA-Z0-9]|\s+[a-zA-Z0-9]|\s*[A-Z]|\s*[._-]+[a-zA-Z0-9]/g,
      (match, offset) => `${offset === 0 ? '' : '-'}${match.trim().slice(-1)}`,
    )
    .trim()
    .toLowerCase();

export const truncateEllipse = (str: string, length: number): string => {
  return str && str.length > length ? `${str.substring(0, length)}...` : str || '';
};

/**
 * Convert string to human-readable "title"
 * @example titleize('foo_bar_buzz') === 'Foo Bar Buzz'
 * @example titleize('fooBarBuzz') === 'Foo Bar Buzz'
 */
export const titleize = (str: string): string =>
  (str || '')
    .trim()
    .replace(
      /^[a-zA-Z0-9]|\s+[a-zA-Z0-9]|\s*[A-Z]|\s*[._-]+[a-zA-Z0-9]/g,
      (match, offset) => `${offset === 0 ? '' : ' '}${match.trim().slice(-1).toUpperCase()}`,
    );

/*
 * Takes an array of strings and returns a string with the items separated by
 * commas and the last item separated by "and"
 */
export const toSentence = (items: string[], and = 'and'): string => {
  if (items.length === 0) return '';
  else if (items.length === 1) return items[0].toString();

  const res = [];
  for (let i = 0; i < items.length - 1; i++) {
    res.push(items[i], ', ');
  }
  res.push(' ', and, ' ', items[items.length - 1]);
  return res.join('');
};

export const pluralize = (count: number, noun: string, suffix?: string): string =>
  `${noun}${count !== 1 ? (suffix ?? `${noun}`.endsWith('s') ? 'es' : 's') : ''}`;

export const singularize = (noun: string): string => {
  if (noun.endsWith('ies')) return noun.replace(/ies$/, 'y');
  return noun.replace(/s$/, '');
};

export const capitalize = (s: string): string => {
  if (typeof s !== 'string' || !s) return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 *
 * @param {*} name The name of the user
 * @param {*} maxChars The max amount of character after which the name is changed to the replacement
 * @param {*} replaceWith The word the name is going to be replaced with if it passes the max amount of characters
 *
 * Checks if the firstName is too long, if it is, it'll replace it with replaceWith
 */
export const firstNameProcessor = (name: string, maxChars: number, replaceWith: string): string => {
  const firstName = capitalize(name);
  if (firstName.length > maxChars) {
    return replaceWith;
  } else {
    return firstName;
  }
};

type Names = { name: string; firstName: string; lastName: string };

export const cleanPersonFullname = (name: string): Names => {
  const [parsedFirstName, ...last] = (name || '').trim().split(/\s+/);
  const firstName = capitalize(parsedFirstName);
  const lastName = capitalize(last.join(' '));
  name = [firstName, lastName].filter(Boolean).join(' ');

  return { name, firstName, lastName };
};
export const cleanPersonNames = (firstName: string, lastName: string): Names => {
  firstName = capitalize(firstName?.trim());
  lastName = capitalize(lastName?.trim());
  const name = [firstName, lastName].filter(Boolean).join(' ');

  return { name, firstName, lastName };
};

export const isValidEmail = (email: string) => !!email && /^.+@.+\..+$/.test(email);

export const isValidPhone = (phone: string) => !!phone && /^\+?\d{7,15}$/.test(phone);

export const stringContainsPhone = (text: string) =>
  /^(.*?)[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}/.test(text);

export const stringContainsEmail = (text: string) =>
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
    text,
  );

export const stringContainsContactInfo = (text: string) =>
  stringContainsPhone(text) || stringContainsEmail(text);

export const cleanPhone = (phone: string) => (phone || '').replace(/[^\d+]+/g, '');

export const replaceAll = (text: string, prev: string, next: string): string =>
  text.replace(new RegExp(prev.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'), 'g'), next);

export const stringContainsNumber = (text: string) => {
  const regex = /\d+/g;
  return regex.test(text);
};
