import { createError } from 'lib/utils/errors';
import db from 'lib/db/shared';
import { HOST, IS_DEV_ENV, IS_SSR, MOBILE_APP, PLATFORM, UNIQUE_BUILD_HOST } from 'lib/env';
import { decodeQuery, encodeQuery } from 'lib/utils/url';
import propsSerializer, { SerializedJSON } from 'lib/utils/propsSerializer';
import { compactObj } from 'lib/utils';
import { USER_ID_HEADER, AUTH_TOKEN_HEADER, PLATFORM_HEADER } from 'lib/constants/auth';

if (IS_SSR && typeof fetch === 'undefined') throw new Error('Fetch is not defined');

type RequestParams = {
  url: string;
  body?: Record<string, any>;
  query?: Record<string, any>;
  headers?: Record<string, any>;
  method?: string;
  userId?: string;
  deserialize?: boolean;
};

const apiHost = MOBILE_APP ? UNIQUE_BUILD_HOST : HOST;

let authTokenHeader: string | null = null;
export const setAuthTokenHeader = (token: string | null) => {
  authTokenHeader = token || null;
};

export const rawRequest = async <Res = unknown>({
  url,
  query,
  body,
  method,
  headers,
  userId = IS_SSR ? undefined : db.getUser()?.uid,
  deserialize,
}: RequestParams): Promise<{
  res: globalThis.Response;
  resData?: Res;
}> => {
  if (!method) method = body ? 'POST' : 'GET';

  const parsed = new URL(url, apiHost);

  const requestingOurApiServer = parsed.origin === apiHost;

  if (query) parsed.search = encodeQuery({ ...decodeQuery(parsed.search), ...query });

  if (requestingOurApiServer) {
    headers = {
      'content-type': 'application/json',
      [USER_ID_HEADER]: userId || null,
      [AUTH_TOKEN_HEADER]: authTokenHeader,
      [PLATFORM_HEADER]: PLATFORM,
      ...(headers || {}),
    };
  }

  url = parsed.href;

  if (IS_DEV_ENV) console.log('[vanlyapp] api request:', method, url);

  const res = await fetch(url, {
    // we only use cookies on frontend web
    ...(IS_SSR || !requestingOurApiServer ? {} : { credentials: 'same-origin' }),
    method,
    headers: compactObj(headers || {}),
    body: body != null ? JSON.stringify(body) : undefined,
  });

  let resData: Res | undefined;
  const resType = res.headers.get('content-type');
  try {
    if (resType?.includes('text/plain') || resType?.includes('text/html'))
      resData = (await res.text()) as unknown as Res;
    else if (resType?.includes('application/json')) {
      resData = await res.json();

      // by default, deserialize if requesting /api/ routes
      deserialize ??= requestingOurApiServer && parsed.pathname.startsWith('/api/');

      // This deserializes api data, see `lib/middleware/api` for the serialization
      if (deserialize && resData && typeof resData === 'object')
        resData = propsSerializer.deserialize(resData as unknown as SerializedJSON);
    }
  } catch {}

  return {
    res,
    resData,
  };
};

const getMsg = (obj: unknown) => typeof obj === 'string' && obj;

export const request = async <Res = unknown>(params: RequestParams): Promise<Res> => {
  const { res, resData } = await rawRequest<Res>(params);

  if (!res.ok) {
    throw createError(
      res.status,
      (typeof resData === 'string' && resData) ||
        (typeof resData === 'object' &&
          (getMsg((resData as any)?.message) || getMsg((resData as any)?.error))) ||
        undefined,
    );
  }

  return resData!;
};
