import { useState, useEffect, useRef, useMemo } from 'react';
import { usePrevious } from 'react-use';
import type firebase from 'firebase/compat/app';

import { createError, NOT_FOUND } from 'lib/utils/errors';
import type * as Firestore from 'lib/utils/firestore';

type HookResponse<Data> = {
  data: Data | null;
  error: Error | null;
  loading: boolean;
};

/**
 * Pass a collection reference, and whether or not you want
 * live updates from this collection with the `subscribe` option.
 * `subscribe` defaults to `true`
 *
 * @example
 * const { error: profilesError, data: profiles } = useCollection(firestore.collection('profiles'));
 */
export const useCollection = <Doc = unknown>(
  query?: Firestore.Query<Doc>,
  { subscribe = true } = {},
): HookResponse<(Doc & { id: string })[]> => {
  const [data, setData] = useState<(Doc & { id: string })[] | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const subscribeRef = useRef<boolean>(subscribe);

  useEffect(() => {
    if (!query) return undefined;

    const handler = (
      err: Error | null,
      snap: firebase.firestore.QuerySnapshot<Doc> | null,
    ): void => {
      if (!snap || err) {
        setData(null);
        setError(err || new Error('Missing collection'));
      } else {
        setData(
          snap.docs.map((doc) => {
            const d = doc.data() as Doc & { id: string };
            d.id = doc.id;
            return d;
          }),
        );
        setError(null);
      }
    };

    if (subscribeRef.current)
      return (query as firebase.firestore.Query<Doc>).onSnapshot(
        (snap) => handler(null, snap),
        (err) => handler(err, null),
      );
    else
      (query as firebase.firestore.Query<Doc>)
        .get()
        .then((snap) => handler(null, snap))
        .catch((err) => handler(err, null));
    return undefined;
  }, [query]);

  return { error, data, loading: !error && !data } as HookResponse<(Doc & { id: string })[]>;
};

/**
 * Pass a document reference, and whether or not you want
 * live updates from this document with the `subscribe` option
 *
 * @example
 * const { error: profileError, data: profile } = useDoc(firestore.collection('profiles').doc('123'));
 */
export const useDoc = <Doc>(
  docRef?: Firestore.DocumentReference<Doc> | null,
  { subscribe = true, waitForDoc = false } = {},
): HookResponse<Doc & { id: string }> => {
  const subscribeRef = useRef<boolean>(subscribe);

  const docPath = docRef?.path;
  const firestore = docRef?.firestore;
  const memoDocRef = useMemo(
    () => firestore?.doc(docPath || '') as firebase.firestore.DocumentReference<Doc> | undefined,
    [firestore, docPath],
  );

  const value = useRef<HookResponse<Doc & { id: string }>>({
    data: null,
    error: null,
    loading: true,
  });

  const prevDocPath = usePrevious(docPath);
  // reset data when doc path changes
  if (!docPath || prevDocPath !== docPath) {
    value.current = {
      data: null,
      error: null,
      loading: !!memoDocRef,
    };
  }

  const [, update] = useState<number>(0);
  const setValue = (data: HookResponse<Doc & { id: string }>) => {
    value.current = data;
    update((v) => v + 1);
  };

  useEffect(() => {
    if (!memoDocRef) return;

    const handler = (
      err: Error | null,
      snap: firebase.firestore.DocumentSnapshot<Doc> | null,
    ): void => {
      if (!snap || err) {
        setValue({ data: null, error: err || new Error('Unknown document error'), loading: false });
      } else {
        const d = snap.data() as Doc & { id: string };
        if (!d) {
          if (waitForDoc) setValue({ data: null, error: null, loading: true });
          else
            setValue({
              data: null,
              error: createError(NOT_FOUND),
              loading: false,
            });
        } else {
          d.id = memoDocRef.id;
          setValue({ data: d, error: null, loading: false });
        }
      }
    };

    if (subscribeRef.current)
      return memoDocRef.onSnapshot(
        (snap) => handler(null, snap),
        (err) => handler(err, null),
      );
    else
      memoDocRef
        .get()
        .then((snap) => handler(null, snap))
        .catch((err) => handler(err, null));
    return undefined;
  }, [memoDocRef, waitForDoc]);

  return value.current;
};
